import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useDataProvider, useNotify } from 'react-admin';

const LogoutButton = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await dataProvider.get('logout');  // Use appropriate method if not GET
            // Clear any frontend tokens or user data as needed
            localStorage.setItem('isLoggedIn', 'false');
            navigate.push('/login'); // Redirect to login or any other page
        } catch (error) {
            notify('Error during logout');
        }
    };

    return <Button onClick={handleLogout}>Logout</Button>;
};

export default LogoutButton;