import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL + process.env.REACT_APP_API_PATH,
  withCredentials: true, // This will include HTTP-only cookies with every request
});



export default apiClient;
