import { Check } from '@mui/icons-material';
import { List, Datagrid, TextField, Edit, Create, SimpleForm, TextInput, BooleanInput, FormDataConsumer, SelectInput, required} from 'react-admin';
import {
ReferenceArrayInput,
    SelectArrayInput, CheckboxGroupInput, useNotify, useRefresh, useRedirect
  } from 'react-admin';

  import { useQuery } from 'react-query';
  import apiClient from "../apiClient"; // Make sure to update the path
  import { useGetOne } from 'react-admin';
  import { useParams } from 'react-router-dom';
  import { useDataProvider } from "react-admin";
import { red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import dataProvider from '../dataProvider';
import { Button } from '@mui/material';


export const UserSettingsCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const handleSave = async (values) => {
    try {
      // console.log("using handlesave method")
      const { data } = await dataProvider.create('profile/', { data: values });
      notify('User created, please set shop privileges if user is not an admin, and then save');
      redirect(`/profile/${data.id}`);
      refresh();
    } catch (error) {
      // console.log("Error creating user")
      // console.log(error)
      notify(`Error: ${JSON.stringify(error.response.data)}`, 'warning');  // Convert the error data to a string
    }
  }


return(  <Create {...props}>
      <SimpleForm onSubmit={handleSave} >
          <TextInput source="user.username" label="Username" />
          <TextInput source="user.email" label="Email" />
          <TextInput source="user.first_name" label="First Name" />
          <TextInput source="user.last_name" label="Last Name" />
          <SelectInput source="account_level" label="Account Level" choices={[
              { id: '1', name: 'Company Admin' },
              { id: '2', name: 'Standard User' },
          ]} />

      </SimpleForm>
  </Create>
);
        }

        export const UserSettingsList = (props) => {
          const dataProvider = useDataProvider();
          const navigate = useNavigate();
          const [data, setData] = useState([]);
          const [error, setError] = useState(null);
      
          useEffect(() => {
              const fetchData = async () => {
                  try {
                      const response = await dataProvider.getList('profile', props);
                      setData(response.data);
                  } catch (err) {
                      if (err.status === 403) {
                          navigate('/'); // Redirect to main page on 403 error
                      } else {
                          setError(err);
                      }
                  }
              };
      
              fetchData();
          }, [dataProvider, props, navigate]);
      
          if (error) {
              return <div>Error: {error.message}</div>;
          }
      
          return (
              <List {...props}>
                  <Datagrid rowClick="edit">
                      <TextField source="user.username" label="Username" />
                      <TextField source="user.email" label="Email" />
                      <TextField source="user.first_name" label="First Name" />
                      <TextField source="user.last_name" label="Last Name" />
                      <TextField source="user.is_active" label="Active" />
                      <TextField source="display_string" label="Account Level" />
                  </Datagrid>
              </List>
          );
      };



export const UserSettingsEdit = (props) => {
    const notify = useNotify();
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Get the history object
    const { id: recordId } = useParams();
    // console.log("recordId is " + recordId)
    const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    
    apiClient.get('/current_user').then((response) => {
      // console.log(response.data);
      setCurrentUser(response.data);
    });
  }, []);

    // Fetching the specific user profile data
    const { data: userSettings } = useGetOne('profile/', { id: recordId });
    
    const { data: shops, isLoading } = useQuery('shops', () =>
    
    apiClient.get('/shops_for_privileges?user_id=' + recordId).then((response) => {
     // console.log("response is " + JSON.stringify(response.data)); 
      return response.data;}).catch((error) => {
        // console.log("error is " + error); 
        return [];})
    )
    
    // Transforming the shops data into the format needed for CheckboxGroupInput
    const choices = isLoading || !Array.isArray(shops)
  ? []
  : shops.map((shop) => ({
      id: shop.id,
      name: shop.name,
    }));
  
  // Getting the pre-existing shop_privileges values
  


  const shopPrivileges = userSettings ? userSettings.shop_privileges : [];

  const account_level_choices = userSettings? Object.entries(userSettings.account_level_choices || {}).map(([key, value]) => ({
    id: parseInt(key, 10),
    name: value
})) : [];

  const dataProvider = useDataProvider();
  const handleSave = (values) => {
    // console.log("values are " + JSON.stringify(values));
  
    // Split the user and profile values
    const userFields = {
      username: values.user.username,
      email: values.user.email,
      first_name: values.user.first_name,
      last_name: values.user.last_name,
      is_active: values.user.is_active,
    };
  
    const profileFields = {
      shop_privileges: values.shop_privileges,
      account_level: values.account_level,
    };
  
    dataProvider
      .update("updateProfile", {
        id: recordId,
        data: { user: userFields, profile: profileFields },
        previousData: {},
      })
      .then((response) => {
        // console.log("Successfully updated user and profile:", response);
        navigate('/profile/');
      })
      .catch((error) => {
        console.log("Failed to update user and profile:", error);
        setError('An error occurred while saving. Please try again.');
      });
  };

  const handlePasswordReset = async () => {
    try {
      console.log("userSettings.user.email is " + userSettings.user.email);
      const email = userSettings.user.email;
      const response = await apiClient.post('/password-reset/', { email });
      notify('Password reset link sent to ' + userSettings.user.email);
    } catch (error) {
      notify('Failed to send password reset link', 'warning');
    }
  };

  if (error) {
    notify('Something went wrong', 'warning');
  }
  
  if(error){
  notify('Something went wrong', 'warning');}




  return (
    <Edit {...props}>
      <SimpleForm onSubmit={handleSave}>
        <TextInput source="user.username" label="Username" />
            <TextInput source="user.email" label="Email" />
            <TextInput source="user.first_name" label="First Name" />
            <TextInput source="user.last_name" label="Last Name" />
            <BooleanInput source="user.is_active" label="Active" />
        {/* ... other fields */}
        <SelectInput source="account_level" label="Permissions Level" choices={account_level_choices} validate={required()} />
        <FormDataConsumer>
        {({ formData, ...rest }) => 
          formData.account_level == '2' && (
            <CheckboxGroupInput
              label="User Can Access These Shops:"
              source="shop_privileges"
              choices={choices}
              defaultValue={shopPrivileges} // Setting the default values
              {...rest}
            />
          )
        }
      </FormDataConsumer>
        <Button type="button" variant="contained" color="secondary" onClick={handlePasswordReset}>
          Reset Password
        </Button>
      </SimpleForm>
    </Edit>
  );
};



