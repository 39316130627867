import { Sidebar, MenuItemLink, usePreferences} from 'react-admin';
import GridViewIcon from '@mui/icons-material/GridView';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import { useLogout } from 'react-admin';
import { useLocation } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import { Container, Icon } from '@mui/material';
import {Box, Drawer, Collapse } from '@mui/material';
import { SidebarClasses, useLocale, useSidebarState } from 'react-admin';
import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import AssessmentIcon from '@mui/icons-material/Assessment';
import IconButton from '@mui/material/IconButton';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import GroupIcon from '@mui/icons-material/Group';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useAuthenticated, useRedirect, useNotify } from "react-admin";
import PunchClockIcon from '@mui/icons-material/PunchClock';
import logo from '../larger_logo_white.png';
import logo_small from '../logo_main_white.png';
import Tooltip from '@mui/material/Tooltip';

const MySidebar = ({props,sidebarState,toggleSidebar,onMenuClick,drawerWidth,miniDrawerWidth}) => { 
  const redirectTo = useRedirect();
  const notify = useNotify();

  try {
      useAuthenticated();
  } catch (error) {
      notify('You are not authenticated!');
      redirectTo('/login');
  }
 
 
  const iconSize = '100%'
    const logout = useLogout();
    const location = useLocation();
    // const [open, setOpen] = React.useState(true);
    // const toggleSidebar = () => {setOpen(!open);
    // console.log("Switching sidebar state")
    // }
    
    const menuItemLinkStyle = {
        color: 'white',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)', // Optional: if you want a slight white background on hover
        },
        overflow:'hidden',
        fontSize: '125%',
        paddingRight:0,
        width: sidebarState ? drawerWidth : miniDrawerWidth,
    };
    
    const IconText = ({text, sidebarState}) => {
      if(sidebarState) return(
      <span>{text}</span>)
      else return null;
    }

    const fullContents=  <div>
    <Tooltip title={!sidebarState ? "Dashboard" : ""} placement="right">
      <MenuItemLink 
        to="/kanban"
        primaryText=<IconText text="Dashboard" sidebarState={sidebarState} />
        leftIcon={<GridViewIcon style={{ color: 'white', fontSize: iconSize, marginRight: '0.3rem' }}/>}
        onClick={onMenuClick}
        sx={menuItemLinkStyle}
      />
    </Tooltip>

    <Tooltip title={!sidebarState ? "Reports" : ""} placement="right">
      <MenuItemLink 
        to="/reports"
        primaryText=<IconText text="Reports" sidebarState={sidebarState} />
        leftIcon={<AssessmentIcon style={{ color: 'white', fontSize: iconSize, marginRight: '0.3rem' }} />}
        onClick={onMenuClick}
        sx={menuItemLinkStyle}
      />
    </Tooltip>

    <Tooltip title={!sidebarState ? "Manage Users" : ""} placement="right">
      <MenuItemLink 
        to="/profile"
        primaryText=<IconText text="Manage Users" sidebarState={sidebarState} />
        leftIcon={<GroupIcon style={{ color: 'white', fontSize: iconSize, marginRight: '0.3rem' }} />}
        onClick={onMenuClick}
        sx={menuItemLinkStyle}
      />
    </Tooltip>

    <Tooltip title={!sidebarState ? "Scan Invoice" : ""} placement="right">
      <MenuItemLink 
        to="/parts/create"
        primaryText=<IconText text="Scan Invoice" sidebarState={sidebarState} />
        leftIcon={<CameraAltIcon style={{ color: 'white', fontSize: iconSize, marginRight: '0.3rem' }} />}
        onClick={onMenuClick}
        sx={menuItemLinkStyle}
      />
    </Tooltip>

    <Tooltip title={!sidebarState ? "Settings" : ""} placement="right">
      <MenuItemLink 
        to="/notificationSettings"
        primaryText=<IconText text="Settings" sidebarState={sidebarState} />
        leftIcon={<SettingsIcon style={{ color: 'white', fontSize: iconSize, marginRight: '0.3rem' }} />}
        onClick={onMenuClick}
        sx={menuItemLinkStyle}
      />
    </Tooltip>

    <Tooltip title={!sidebarState ? "Scheduling" : ""} placement="right">
      <MenuItemLink 
        to="/schedule"
        primaryText=<IconText text="Scheduling" sidebarState={sidebarState} />
        leftIcon={<PunchClockIcon style={{ color: 'white', fontSize: iconSize, marginRight: '0.3rem' }} />}
        onClick={onMenuClick}
        sx={menuItemLinkStyle}
      />
    </Tooltip>

    
  </div>
  

    return(
        <Collapse in={sidebarState} collapsedSize={miniDrawerWidth} orientation='horizontal' >
        <Drawer
          key="drawer"
          sx={{
            width: sidebarState ? drawerWidth : miniDrawerWidth,
            marginLeft:0,
            '& .MuiDrawer-paper': {
              backgroundColor: "#89A7B6",
              padding: 0,
              margin: 0,
              borderRight: '3px solid rgba(0, 0, 0, 0.12)',
            }
          }}
        variant="permanent"
        anchor="left"

        
      >

             <div style={{paddingTop:5}}>               
        {fullContents}
        </div>  
</Drawer>
</Collapse>

    )

 


}

export default MySidebar;
