import React, { useState, useEffect } from "react";
import { Select, MenuItem } from "@mui/material";
import dataProvider from "../dataProvider"; // Path to your dataProvider file

const ShopSelector = () => {
  const [selectedValue, setSelectedValue] = useState(localStorage.getItem("selectedShopId"));
  const [shops, setShops] = useState([]);
  const shopevent = new Event("shopChanged");
  useEffect(() => {
    const fetchShops = async () => {
      try {
        if(localStorage.getItem("isLoggedIn")=="true"){
        const { data } = await dataProvider.getList("shops",{});
        // console.log("Retrieved shops data")
        // console.log(data)
        setShops(data);
        // console.log(typeof data[0].id)
        let selected = localStorage.getItem("selectedShopId")

        if (selected=="null" && data.length) {
          selected = data[Math.floor(Math.random() * data.length)].id
          // console.log("Selected+++++++++++++++", selected)
        }

        setSelectedValue(selected);
        // console.log("Storing shop id value", + selected)
        localStorage.setItem("selectedShopId", selected);
        }else{
          // console.log("Not logged in")
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
  
    fetchShops();
  }, []);

  const handleChange = async (event) => {
    const selectedShopId = event.target.value;
  

        // Store the selected shop id in local storage
        localStorage.setItem("selectedShopId", selectedShopId);
  
        // Update the local state to reflect the change in the UI
        setSelectedValue(selectedShopId);
  
        // Trigger the event so that other components can react to the change of shop
        window.dispatchEvent(shopevent);

  };
  
  if(shops == [] || selectedValue===null || selectedValue=="null"){ return (<div>Loading...</div>)}else{
  return (
    <Select value={selectedValue} onChange={handleChange}>
      {shops.map((shop) => (
        <MenuItem key={shop.id} value={shop.id}>
          {shop.name}
        </MenuItem>
      ))}
    </Select>
  );}
};

export default ShopSelector;
