import React, { useState, useEffect, useMemo } from 'react';
import { useRedirect } from 'react-admin';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
// YourComponent.js
import './calendstyle.css';

import { Button, Box,FormControlLabel, Switch } from '@mui/material';
import apiClient from "../apiClient";
import FlagIcon from '@mui/icons-material/Flag';
import Tooltip from '@mui/material/Tooltip';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton,MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import dataProvider from '../dataProvider';
import CircularProgress from '@mui/material/CircularProgress';


const localizer = momentLocalizer(moment);

const ScheduleComponent = () => {
  const redirect = useRedirect();

  // // Set the height based on the current window's innerHeight
  const height = window.innerHeight * 0.8; // e.g. 80% of the view height. Adjust as needed.
  const [dateRange, setDateRange] = useState({ start: null, end: null }); // Initialize state variable for calendar height
  const [view, setView] = useState('month')
  const [appointments, setAppointments] = useState([]);
  const [seeAllLocations, setSeeAllLocations] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [shops, setShops] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [formData, setFormData] = useState({
    appointmentDate: '',
    customerName: '',
    vehicleYear: null,
    vehicleMake: '',
    vehicleModel: '',
    insuranceCompany: '',
    claimNumber: '',
    payer: '',
    appointmentType: '',
    phoneNumber: '',
    customerEmail: '',
    ro:'',
  });
  

  const onChangeShop = async () => {
    setLoading(true);
    try {
      const selectedShopId = localStorage.getItem('selectedShopId');
      const { data } = await apiClient.get('scheduleview/');
      
      if (!seeAllLocations && selectedShopId) {
        setAppointments(data.filter(appointment => appointment.shop.id === parseInt(selectedShopId)));
      } else {
        setAppointments(data);
      }
    } catch (error) {
      console.error('Error fetching appointments on shop change:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const filterAppointments = () => {
      let filtered = appointments;
  
      // Filter by selected shop
      const selectedShopId = localStorage.getItem('selectedShopId');
      if (!seeAllLocations && selectedShopId) {
        filtered = filtered.filter(appointment => appointment.shop.id === parseInt(selectedShopId));
      }
  
      // Filter by search query
      if (searchQuery.trim() !== '') {
        filtered = filtered.filter(appointment =>
          appointment.customerName.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
  
      setFilteredAppointments(filtered);
    };
  
    filterAppointments();
  }, [searchQuery, seeAllLocations, appointments]);
  

  useEffect(() => {
    async function fetchShops() {
      const { data } = await dataProvider.getList("shops", {});
      setShops(data);
    }
    fetchShops();
  }, []);


  // useEffect(() => {
  //   const fetchAppointments = async () => {
  //     try {
  //       const response = await apiClient.get('scheduleview/');
  //       console.log('Appointments:', response.data);
  //       setAppointments(response.data);
  //     } catch (error) {
  //       console.error('Error fetching appointments:', error);
  //     }
  //   };
  //   fetchAppointments();
  // }, []);

  useEffect(() => {
    async function fetchAppointments() {
      setLoading(true);
      try {
        const selectedShopId = localStorage.getItem('selectedShopId');
        const { data } = await apiClient.get('scheduleview/');
  
        if (!seeAllLocations && selectedShopId) {
          setAppointments(data.filter(appointment => appointment.shop.id === parseInt(selectedShopId)));
        } else {
          setAppointments(data);
        }
      } catch (error) {
        console.error('Error fetching appointments:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchAppointments();
  }, [seeAllLocations]);

  useEffect(() => {
    window.addEventListener('shopChanged', onChangeShop);
    return () => {
      window.removeEventListener('shopChanged', onChangeShop);
    };
  }, [seeAllLocations]);
  
  useEffect(() => {
    async function initialFetchAppointments() {
      setLoading(true);
      try {
        const selectedShopId = localStorage.getItem('selectedShopId');
        const { data } = await apiClient.get('scheduleview/');
  
        // if (selectedShopId) {
        //   setAppointments(data.filter(appointment => appointment.shop.id === parseInt(selectedShopId)));
        // } else {
          setAppointments(data);
        // }
      } catch (error) {
        console.error('Error fetching appointments:', error);
      } finally {
        setLoading(false);
      }
    }
    initialFetchAppointments();
  }, []);
  
  

  const appointmentTypes = [
    { label: 'Drop-Off Repairs', color: '#77DD77' },
    { label: 'Estimate', color: '#AEC6CF' },
    { label: 'Tow-in', color: '#CBAACB' },
    {label: "Warranty", color: "#FFB347"}
  ];


  const events = filteredAppointments.map(item => {
    const start = new Date(item.appointmentDate);
    const end = new Date(item.appointmentDate); 
 
    const start_string=new Date(item.appointmentDate).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
  });
  
  const vehicleInfo = (item.vehicleMake && item.vehicleMake.trim() !== '' && item.vehicleModel && item.vehicleModel.trim() !== '') ?
  `${item.vehicleYear} ${item.vehicleMake} ${item.vehicleModel}` :
  'unknown vehicle';

// Function to format customer name as "Lastname, Firstname"
const formatCustomerName = (name) => {
  if (!name || name.trim() === '') return '';
  const parts = name.trim().split(' ');
  if (parts.length < 2) return name; // If there are not at least two parts, return the name as is
  const firstName = parts[0];
  const lastName = parts.slice(1).join(' '); // Join all parts except the first one for the last name
  return `${lastName}, ${firstName}`;
};

// Determine the title based on the availability of ro, claimNumber, and fallback to customerName

let roString = '';

if (item.ro && item.ro.trim() !== '') {
    roString = `RO# ${item.ro} `;
}
  const formattedCustomerName = formatCustomerName(item.customerName);
  const title = `${start_string} ${roString}${formattedCustomerName} ${vehicleInfo}`;


return {
  id: item.id,
  title: title,
  start: start,
  end: end,
  allDay: false,
  allParts: item.allParts,
  readyPaint: item.readyPaint,
  insuranceCompany: item.insuranceCompany,
  appointmentType: item.appointmentType,
  phoneNumber: item.phoneNumber,
  customerEmail: item.customerEmail,
  ro: item.ro,
};






  });
  
  const appointmentTypeColorMapping = appointmentTypes.reduce((acc, type) => {
    acc[type.label] = type.color;
    return acc;
  }, {});

  const handleSubmit = async () => {
    try {
      const payload = {
        ...formData,
        shop_id: formData.shop, // Ensure shop_id is included in the payload
      };
      const response = await apiClient.post('scheduleview/', payload);
      console.log('New appointment created:', response.data);
      setOpenDialog(false);
      setAppointments([...appointments, response.data]);
    } catch (error) {
      console.error('Error creating appointment:', error);
      alert('Failed to create appointment. Please try again.');
    }
  };
  

  const handleViewChange = (newView, date) => {
    setView(newView);
  
    let start, end;
    if (newView === 'day') {
      start = end = moment(date).startOf('day').toDate();
    } else if (newView === 'week') {
      start = moment(date).startOf('week').toDate();
      end = moment(date).endOf('week').toDate();
    } else {
      start = end = null;
    }
  
    setDateRange({ start, end });
  };
  
  const calculateHeight = () => {
    if (view === 'month') {
      return '150vh';
  };
  }

  const CustomEvent = ({ event }) => {
    return (
      <div>

        {event.title}
      </div>
    );
  };
//button with "New Appointment"
const formats = useMemo(() => ({
  eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
    localizer.format(start, 'hh:mm a', culture),
}), []);

return (
  <div style={{ height: calculateHeight() }}>
    {loading ? (
      <div>Loading...</div>  // Display loading spinner or message
    ) : (
    <>
    <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex' }}>
      <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>New Appointment</Button>
      <FormControlLabel
        control={<Switch checked={seeAllLocations} onChange={() => setSeeAllLocations(!seeAllLocations)} />}
        label="See All Locations"
        style={{ marginLeft: '10px' }}
      />
      <TextField
  label="Search by Customer Name"
  variant="outlined"
  value={searchQuery}
  onChange={(e) => setSearchQuery(e.target.value)}
  style={{ marginLeft: '10px' }}
/>

    </div>



    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>
        Create New Appointment
        <IconButton onClick={() => setOpenDialog(false)} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
      <TextField
          margin="dense"
          label="Shop"
          select
          fullWidth
          value={formData.shop}
          onChange={(e) => setFormData({ ...formData, shop: e.target.value })}
        >
          {shops.map((shop) => (
            <MenuItem key={shop.id} value={shop.id}>
              {shop.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          autoFocus
          margin="dense"
          label=""
          type="datetime-local"
          fullWidth
          value={formData.appointmentDate}
          onChange={(e) => setFormData({ ...formData, appointmentDate: e.target.value })}
        />
        <TextField
          margin="dense"
          label="Customer Name"
          type="text"
          fullWidth
          value={formData.customerName}
          onChange={(e) => setFormData({ ...formData, customerName: e.target.value })}
        />
        <TextField
          margin="dense"
          label="Vehicle Year"
          type="number"
          fullWidth
          value={formData.vehicleYear}
          onChange={(e) => setFormData({ ...formData, vehicleYear: e.target.value })}
        />
        <TextField
          margin="dense"
          label="Vehicle Make"
          type="text"
          fullWidth
          value={formData.vehicleMake}
          onChange={(e) => setFormData({ ...formData, vehicleMake: e.target.value })}
        />
        <TextField
          margin="dense"
          label="Vehicle Model"
          type="text"
          fullWidth
          value={formData.vehicleModel}
          onChange={(e) => setFormData({ ...formData, vehicleModel: e.target.value })}
        />
        <TextField
          margin="dense"
          label="Insurance Company"
          type="text"
          fullWidth
          value={formData.insuranceCompany}
          onChange={(e) => setFormData({ ...formData, insuranceCompany: e.target.value })}
        />
        <TextField
          margin="dense"
          label="Claim Number"
          type="text"
          fullWidth
          value={formData.claimNumber}
          onChange={(e) => setFormData({ ...formData, claimNumber: e.target.value })}
        />
        <TextField
          margin="dense"
          label="RO#"
          type="text"
          fullWidth
          value={formData.ro}
          onChange={(e) => setFormData({ ...formData, ro: e.target.value })}
        />
<TextField
  margin="dense"
  label="Payer"
  select
  fullWidth
  value={formData.payer}
  onChange={(e) => setFormData({ ...formData, payer: e.target.value })}
>
  <MenuItem value="Insurance">Insurance</MenuItem>
  <MenuItem value="Self-Pay">Self-Pay</MenuItem>
</TextField>
<TextField
  margin="dense"
  label="Appointment Type"
  select
  fullWidth
  value={formData.appointmentType}
  onChange={(e) => setFormData({ ...formData, appointmentType: e.target.value })}
>
  <MenuItem value="Drop-Off Repairs">Drop-Off Repairs</MenuItem>
  <MenuItem value="Estimate">Estimate</MenuItem>
  <MenuItem value="Tow-in">Tow-in</MenuItem>
  <MenuItem value="Warranty">Warranty</MenuItem>
</TextField>

        <TextField
          margin="dense"
          label="Phone Number"
          type="text"
          fullWidth
          value={formData.phoneNumber}
          onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
        />
        <TextField
          margin="dense"
          label="Customer Email"
          type="email"
          fullWidth
          value={formData.customerEmail}
          onChange={(e) => setFormData({ ...formData, customerEmail: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>



    <Calendar
    formats={formats}
      popup
      onSelectEvent={event => { window.open(`/#/schedule/${event.id}/show/`, '_blank') }}
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{ height: '100%' }}
      defaultView={'month'}
      views={['month', 'week', 'day']}
      components={{
        event: CustomEvent,
      }}
      tooltipAccessor={event => `Type: ${event.appointmentType} - ${event.title}`}

      eventPropGetter={
         (event) => {
          let backgroundColor = appointmentTypeColorMapping[event.appointmentType] || "#ffffff";
          let color = ["#77DD77", "#AEC6CF", "#CBAACB"].includes(backgroundColor) ? "#000000" : "#ffffff";
          return { style: { backgroundColor, color } };
        }
    }
      min={new Date(0, 0, 0, 6)}
      max={new Date(0, 0, 0, 18)}
      onView={handleViewChange}
    />
      </>
    )}
  </div>
  
);
};

export default ScheduleComponent;
