import { memo, forwardRef } from "react";
import { Box, Typography } from "@mui/material";
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { FixedSizeList as List } from 'react-window';
import CardWrapper from "./CardWrapper";

const Row = memo(forwardRef(({ index, style, data }, ref) => {
  const cardId = data.filteredCardIds[index];
  const cardData = data.allData.find(item => item.id === cardId);
  // console.log("cardData", cardData);
  if (!cardData) {
    return null;  // Render nothing if cardData is undefined
  }
  return (
    <Draggable draggableId={String(cardData.id)} index={parseInt(index)} key={cardData.id}>
      {(provided,snapshot) => (
        <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{
          ...style,
          transition: "all 0.3s ease",
          opacity: snapshot.isDragging ? 0.8 : 1,
          transform: snapshot.isDragging ? "scale(1.05) rotate(-1deg)" : "scale(1)"
        }}
      >
          <CardWrapper
            KanbanCard={data.KanbanCard}
            cardData={cardData}
            uid={data.uid}
            setData={data.setData}
          />
        </div>
      )}
    </Draggable>
  );
}))





export const Column = ({ KanbanCard, stage, cardIds, data, uid, setData }) => {
  const filteredCardIds = cardIds
  const itemCount = cardIds.length;

  const renderClone = (provided, snapshot, rubric, KanbanCard, uid, setData, filteredCardIds) => {
    const cardData = data.find(item => item.id === filteredCardIds[rubric.source.index]);
    return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
    >
      <CardWrapper
        KanbanCard={KanbanCard}
        cardData={cardData}
        uid={uid}
        setData={setData}
      />
    </div>
  );
}

const stageColorMapping = {
  "Repair Planning": "#FAE9B0", // Replace with actual stage names and colors
  "Waiting to Start": "#F1AE6A",
  "Frame": "#CB4C4F",
  "Mechanical": "#B66C80",
  "Body": "#895B96",
  "Refinish": "#709FC1",
  "Assembly": "#90B270",
  "Detail": "#5F8356",
  "Ready for Delivery": "#202424",
  // Add more stages as needed...
};


const calculateHeight = () => {
  return window.innerHeight * 0.75; // 80% of the viewport height
};

return(
  <Box
    sx={{
      alignItems:'center',
      flex: "1 1 auto",
      minWidth: "300",
      maxWidth: "300",
      paddingRight: "1em",
      paddingTop: "8px",
      paddingBottom: "16px",
      bgcolor: "#eaeaee",
      overflow:'visible',
      "&:first-of-type": {
        paddingLeft: "10px",
        borderTopLeftRadius: 5,
      },
      "&:last-child": {
        paddingRight: "5px",
        borderTopRightRadius: 5,
      },

    }}
  >
<Typography align="center" variant="subtitle1" sx={{width:'100%',paddingBottom:1, backgroundColor: stageColorMapping[stage.name] || "#000000", color: stageColorMapping[stage.name] === "#202424" ? "#ffffff" : "#000000", padding: '0.5em'}}>
  {stage.name}
</Typography>
      <Droppable droppableId={stage.id} mode="virtual"   renderClone={(provided, snapshot, rubric) => renderClone(provided, snapshot, rubric, KanbanCard, uid, setData, filteredCardIds)}>  
        {(provided, snapshot) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            // ...rest of your styles
          >
            <List
              overscanCount={5} 
              height={calculateHeight()}  // Adjust height accordingly
              itemCount={itemCount}
              itemSize={250}  // Adjust item size accordingly
              width={300}  // Adjust width accordingly
              itemData={{ KanbanCard, uid, setData, filteredCardIds, allData: data }}
              outerRef={provided.innerRef}
            >
              {Row}
            </List>
          
          </Box>
        )}
      </Droppable>
    </Box>
  );
};