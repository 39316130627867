import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, TextField, makeStyles } from '@material-ui/core';
import {  Login, useRedirect } from 'react-admin';
import apiClient from '../apiClient'; // Import your apiClient
// Reuse the same styling as PasswordResetRequest for consistency
const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
    width: '100%', // You can adjust the width as needed
  },
  submitButton: {
    marginBottom: theme.spacing(2),
  },
}));

const PasswordResetConfirm = ({ onBack }) => {
  const [newPassword, setNewPassword] = useState('');
  const { uidb64, token } = useParams();
  const classes = useStyles();
    const Navigate = useRedirect();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await apiClient.post(`/password-reset-confirm/${uidb64}/${token}/`, { new_password: newPassword });
      alert(response.data.message); // Consider using a more integrated notification system
      Navigate('/login')
      // Redirect to login page or any other page
    } catch (error) {
      alert(error.response.data.error); // Consider using a more integrated notification system
    }
  };

  return (
    <Login>
    <form onSubmit={handleSubmit} className={classes.form} style={{padding:20}}>
      <TextField
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        placeholder="Enter new password"
        required
        className={classes.inputField}
      />
      <Button type="submit" variant="contained" color="primary" className={classes.submitButton}>
        Reset Password
      </Button>

      
    </form>
    </Login>
  );
};

export default PasswordResetConfirm;
