import { useState, useEffect } from "react";

import KanbanBoard from "./kanban-style-board";
import RepairOrderCard from "./RepairOrderCard";
import { useDataProvider, useAuthenticated, useRedirect, useNotify } from "react-admin";
import ReconnectingWebSocket from 'reconnecting-websocket';
import CalendarComponent from "./CalendarComponent";
import { FormControl, InputLabel, Select, MenuItem,  IconButton, TextField,Button,Typography } from '@mui/material';

import RepairOrderList from "./RepairOrderList";
import apiClient from "../apiClient";
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SearchIcon from '@material-ui/icons/Search';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FlagIcon from '@mui/icons-material/Flag';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';

const AutomobileKanban = (props) => {

  const redirectTo = useRedirect();
  const notify = useNotify();
const [searchValue, setSearchValue] = useState('');


  const stageColorMapping = {
    "Repair Planning": "#FAE9B0", // Replace with actual stage names and colors
    "Waiting to Start": "#F1AE6A",
    "Frame": "#CB4C4F",
    "Mechanical": "#B66C80",
    "Body": "#895B96",
    "Refinish": "#709FC1",
    "Assembly": "#90B270",
    "Detail": "#5F8356",
    "Ready for Delivery": "#202424",
    // Add more stages as needed...
  };
  const darkColors = ['#202424',  /* other dark colors */];
  const getTextColor = (backgroundColor) => {
    return darkColors.includes(backgroundColor) ? '#fff' : '#000';
  };
  const authResponseCheck = async (response) => {
    try {
      //console.log("Checking auth response")
      //console.log(response)
      if (response.status === 401) { //console.log("401 detected");
       }
      if (response.status === 403) { //console.log("403 detected"); 
    }
    }

    catch (e) {
      //console.log("Error: " + e)

    }
  }




  try {
    useAuthenticated();
  } catch (error) {
    notify('You are not authenticated!');
    redirectTo('/login');
  }


  const dataProvider = useDataProvider();
  const [view, setView] = useState('kanban');
  const [dateField, setDateField] = useState('startInDate');
  const [stageField, setStageField] = useState(null);
  const [stages, setStages] = useState([]);
  const [kanbanData, setKanbanData] = useState([]);

  const [selectedShop, setSelectedShop] = useState(localStorage.getItem("selectedShopId"));
  const [checking, setChecking] = useState(false);
  const [open, setOpen] = useState(true);
  const [prevKanbanData, setPrevKanbanData] = useState(null);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [uid, setUid] = useState(Math.random().toString(36).substring(2) + Date.now().toString(36))
  const [filteredData, setFilteredData] = useState(kanbanData);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState("None");
  const [searchTextValue, setSearchTextValue] = useState("");


  useEffect(() => {
    if (toastMessage) {
      const timer = setTimeout(() => {
        setToastMessage(null);
      }, 3000); // Hide after 3 seconds

      return () => clearTimeout(timer); // Clear the timer if the component unmounts
    }
  }, [toastMessage]);

  useEffect(() => {
    // Retrieve the value from local storage
    const storedValue = localStorage.getItem('kanbanview');
    // Check if there is a stored value and set the state
    if (storedValue) {
      setView(storedValue);
    }else{
      setView('kanban');
    }
  }, []);

const fieldsToSearch = ['ro']; // replace with your field names

const [lastFilter, setLastFilter] = useState('search');
const [isFiltered, setIsFiltered] = useState(false);

useEffect(() => {
  if (searchValue !== '') {
    setIsFiltered(true);
    setLastFilter('search');
    const result = kanbanData.filter(item =>
      fieldsToSearch.some(field =>
        String(item[field]).toLowerCase().includes(searchValue.toLowerCase())
      )
    );
    setFilteredData(result);
    setSelectedOption('None');
    setSelectedDate(null);
  } else if (lastFilter === 'search') {
    console.log("Search value is empty")
    console.log(kanbanData)
    setSearchTextValue('');
    setIsFiltered(false);
    setSelectedOption('None');
    setSelectedDate(null);
  }

}, [searchValue, kanbanData, lastFilter]);

useEffect(() => {
  if (selectedOption !== 'None') {
    setIsFiltered(true);
    setLastFilter('option');
    setSelectedDate(null);
    setSearchValue('');
    setSearchTextValue('');
  } else if (lastFilter === 'option') {
    setIsFiltered(false);
  }
}, [selectedOption, lastFilter]);

useEffect(() => {
  if (selectedOption !== 'None') {
    const result = kanbanData.filter(item => item[selectedOption] === true);
    setFilteredData(result);
  } else if (lastFilter === 'option' && !isFiltered) {
    setFilteredData(kanbanData);
  }
}, [selectedOption, kanbanData, lastFilter, isFiltered]);

useEffect(() => {
  if (selectedDate) {
    setIsFiltered(true);
    setLastFilter('date');
    setSelectedOption('None');
    setSearchValue('');
    setSearchTextValue('');
  } else if (lastFilter === 'date') {
    setIsFiltered(false);
  }
}, [selectedDate, lastFilter]);

useEffect(() => {
  if (selectedDate) {
    const selectedDateStr = dayjs(selectedDate).format('MM-DD-YYYY');
    const result = kanbanData.filter(item => dayjs(item.vehicleOutDate, 'MM-DD-YYYY').format('MM-DD-YYYY') === selectedDateStr);
    setFilteredData(result);
  } else if (lastFilter === 'date' && !isFiltered) {
    setFilteredData(kanbanData);
  }
}, [selectedDate, kanbanData, lastFilter, isFiltered]);

//if isFiltered is false, refetch the kanban data

useEffect(() => {
  if (!isFiltered) {
    dataProvider.getList("kanban", { id: selectedShop }).then(({ data }) => {
      setKanbanData(data);
    });
  }
}, [isFiltered, selectedShop]);

useEffect(() => {
  // This effect runs after prevKanbanData has been updated
  console.log("Updated prev kanban", prevKanbanData);
}, [prevKanbanData]);

  useEffect(() => {
    //console.log("Use effect triggered")
    const serverUrl = process.env.REACT_APP_BASE_URL;
    const wsProtocol = process.env.REACT_APP_WS_PROTOCOL;
    const wsUrl = `${wsProtocol}://${serverUrl}/ws/RO_Updates/`;
    const ws = new ReconnectingWebSocket(wsUrl);
    console.log(wsUrl, wsProtocol, serverUrl)

    ws.onopen = (event) => {
      console.log("No kanban data found, fetching new data")
      console.log(kanbanData)

      try {
        if (localStorage.getItem("isLoggedIn") == "true") {
          dataProvider.getList("stages", {}).then(({ data }) => setStages(data));
          dataProvider.getList("shops", {}).then((shopdata) => {
            if (!shopdata.data || shopdata.data.length === 0) {
              setToastMessage('No shops have been set for this user. Talk to your administrator or email admin@revvproduction.com');
              return;
            }
            //console.log("Shop Data")
            //console.log(shopdata)

            let currentshop = localStorage.getItem("selectedShopId")

            if (currentshop === null || currentshop == "null") {
              currentshop = shopdata.data[Math.floor(Math.random() * shopdata.data.length)]
              //console.log("Random Shop: " + currentshop)
              currentshop = currentshop.id
            }

            setSelectedShop(currentshop)
            //console.log(currentshop)
            //console.log("Selected Shop: " + selectedShop)

            dataProvider.getList("kanban", { id: currentshop }).then(({ data }) => {

              //console.log("Kanban")
              //console.log(data)
              if (data.length === 0) {
                setKanbanData([0])

              } else {
                setKanbanData(data);
                const idsToSubscribe = data.map((item) => item.id);
                console.log("Subscribing to: " + idsToSubscribe)
                
                ws.send(JSON.stringify({
                  action: 'subscribe',
                  ids: idsToSubscribe
                }));
                console.log("Subscribed to new data")
              }

            }
            );





          })

        } else {
          //console.log("Not logged in")
        }
      } catch (e) {
        //console.log("Error: " + e)
      }



    };

    ws.onmessage = (event) => {
      if (!updateInProgress) {
        const data = JSON.parse(event.data);
        //console.log('WebSocket message received:', data)
        // Handle the incoming WebSocket data based on its content.
        if (data.message.type === 'ro_update') {
          const updatedItem = kanbanData.find(item => item.id === data.message.id);
          if (data.message.session_id !== uid) {
            // console.log("Update from other browser++++++++++++++++++")
            // console.log(JSON.stringify(data.message.session_id))
            // console.log(JSON.stringify(uid))
            var msg = null
            //console.log(data.message.changeType, "++++++++++++++++++++++++")
            if (data.message.changeType === "stage") {
              msg = "Repair order #" + JSON.stringify(data.message.ro) + " was moved to a new stage by another user."
            } else if (data.message.changeType === "start_date") {
              msg = "Repair order #" + JSON.stringify(data.message.ro) + " start date modified by another user."
            } else if (data.message.changeType === "flag") {
              msg = "Repair order #" + JSON.stringify(data.message.ro) + " flags modified by another user."
            }
            else if (data.message.changeType === "start") { msg = "Repair order #" + JSON.stringify(data.message.ro) + "started by another user." }
            else if (data.message.changeType === "stop") { msg = "Repair order #" + JSON.stringify(data.message.ro) + "stopped by another user." }
            else if (data.message.changeType === "pause") { msg = "Repair order #" + JSON.stringify(data.message.ro) + "paused by another user." }
            if (msg) { setToastMessage(msg) }



           } else { //console.log("Update from this browser++++++++++++++++++") 
          }
          if (!updatedItem) {
            console.log("No kanban data found, fetching new data")
            console.log(kanbanData)

            try {
              if (localStorage.getItem("isLoggedIn") == "true") {
                dataProvider.getList("stages", {}).then(({ data }) => setStages(data));
                dataProvider.getList("shops", {}).then((shopdata) => {
                  if (!shopdata.data || shopdata.data.length === 0) {
                    setToastMessage('No shops have been set for this user. Talk to your administrator or email admin@revvproduction.com');
                    return;
                  }
                  //console.log("Shop Data")
                  //console.log(shopdata)

                  let currentshop = localStorage.getItem("selectedShopId")

                  if (currentshop === null || currentshop == "null") {
                    currentshop = shopdata.data[Math.floor(Math.random() * shopdata.data.length)]
                    //console.log("Random Shop: " + currentshop)
                    currentshop = currentshop.id
                  }

                  setSelectedShop(currentshop)
                  //console.log(currentshop)
                  //console.log("Selected Shop: " + selectedShop)

                  dataProvider.getList("kanban", { id: currentshop }).then(({ data }) => {

                    //console.log("Kanban")
                    //console.log(data)
                    if (data.length === 0) {
                      setKanbanData([0])

                    } else {
                      setKanbanData(data);
                      const idsToSubscribe = data.map((item) => item.id);
                      console.log("Subscribing to: " + idsToSubscribe)
                      
                      ws.send(JSON.stringify({
                        action: 'subscribe',
                        ids: idsToSubscribe
                      }));
                      console.log("Subscribed to new data")
                    }

                  }
                  );





                })

              } else {
                //console.log("Not logged in")
              }
            } catch (e) {
              //console.log("Error: " + e)
            }
          }
          // //console.log("Updated Item+++++++++++++++")
          // //console.log(updatedItem)
          // //console.log(data.message)
          if (updatedItem) {
            console.log("Found kanban data item to update+++++++++++++++")
            //console.log(updatedItem)
            //console.log(data.message)
            setKanbanData(prevKanbanData => {
              return prevKanbanData.map(item => {
                if (item.id === data.message.id) {
                  //console.log("updated item from websocket:", item)
                  return { ...item, ...data.message };
                }
                return item;
              });
            })
            //console.log("Toast message")

            // console.log("Repair order remotely updates")
            // console.log(updatedItem)
            if (updatedItem.stage === data.message.stage) {
              //console.log("Stage is same+++++++++++++++")
            }

          } else {
            //console.log("No kanban data item found to update+++++++++++++++")
            //console.log(kanbanData)
            //console.log(data.message)

          }
        }
        else if (data.message.type === 'bulk_update') {
          //console.log("Bulk update received")
          console.log('bulk update')
          const updatedROs = Object.values(data.message).filter(item => typeof item === 'object' && item.id); // Filter out non-RO entries like 'type'
    
          if (updatedROs.length > 0) {
              setKanbanData(prevKanbanData => {
                  return prevKanbanData.map(item => {
                      const updatedItem = updatedROs.find(updatedRO => updatedRO.id === item.id);
                      if (updatedItem) {
                          return { ...item, ...updatedItem };
                      }
                      return item;
                  });
              });
        }
        if (data.message.session !== uid) {
                  const primaryRO = data.message[data.message.primary];

        // Use the 'ro' field from the primary RO object for the toast message
        if (primaryRO) {
            setToastMessage("RO#" + primaryRO.ro + " updated by another user.");
        }

        }
      }
        else{
          //console.log("Unknown message type")
        }
      } else { //console.log("Update in progress") 
    } }
      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      ws.onclose = (event) => {
        //console.log('WebSocket connection closed:', event);
      };



      // fetch the repair orders for the selected shop
      try {
        if (localStorage.getItem("isLoggedIn") == "true") {
          dataProvider.getList("stages", {}).then(({ data }) => setStages(data));
          dataProvider.getList("shops", {}).then((shopdata) => {
            if (!shopdata.data || shopdata.data.length === 0) {
              setToastMessage('No shops have been set for this user. Talk to your administrator or email admin@revvproduction.com');

              return;
            }
            //console.log("Shop Data")
            //console.log(shopdata)

            let currentshop = localStorage.getItem("selectedShopId")

            if (currentshop === null || currentshop == "null") {
              currentshop = shopdata.data[Math.floor(Math.random() * shopdata.data.length)]
              //console.log("Random Shop: " + currentshop)
              currentshop = currentshop.id
            }

            setSelectedShop(currentshop)
            //console.log(currentshop)
            //console.log("Selected Shop: " + selectedShop)

            dataProvider.getList("kanban", { id: currentshop }).then(({ data }) => {

              //console.log("Kanban")
              //console.log(data)
              if (data.length === 0) {
                setKanbanData([0])

              } else {
                setKanbanData(data);
                let idsToSubscribe = [];
                if (data && data.length > 0) {
                  idsToSubscribe = data.map((item) => item.id);
                }
                //console.log("Subscribing to: " + idsToSubscribe)
                ws.send(JSON.stringify({
                  action: 'subscribe',
                  ids: idsToSubscribe
                }));
              }

            }
            );





          })

        } else {
          //console.log("Not logged in")
        }
      } catch (e) {
        console.log("Error: " + e)
      }
      window.addEventListener("shopChanged", onChangeShop)


      return () => {
        ws.close();
        window.removeEventListener("shopChanged", onChangeShop);
      };


    }, []);

  const onChangeShop = (event) => {
    const newId = localStorage.getItem("selectedShopId")
    //console.log("New Shop: " + newId)

    dataProvider.getList("kanban", { id: newId }).then(({ data }) => {

      //console.log("Kanban")
      //console.log(data)
      if (data.length === 0) {
        setKanbanData([0])
        setSelectedShop(newId);
      } else {
        setKanbanData(data);
        setSelectedShop(newId);
      }

    }
    );
  }


  const onChangeStage = async (affectedCards, primary) => {
    //console.log("Updating stage for repair orders:", affectedCards);
    setUpdateInProgress(true);
    console.log("Update in progress")
    console.log("kanban", kanbanData)
    console.log("prev kanban", prevKanbanData)
    setPrevKanbanData(kanbanData)
    console.log("prev kanban", prevKanbanData)
    console.log(kanbanData)
    let data = { cards: affectedCards, session: uid, changeType: "stage", primary: primary }

    try {
      const response = await apiClient.patch(`bulk_update/`, data);
      // Unwrapping one level
      console.log("doing bulk update on client side")
      authResponseCheck(response)
      return response.data;
    } catch (error) {
      authResponseCheck(error.response)
      console.error(error);
      return [];
    }


  };

  if (stages.length === 0 || kanbanData.length === 0 || localStorage.getItem("isLoggedIn") == "false") {
    return <div style={{ color: 'black' }}>Loading...        {toastMessage && (
      <div style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        padding: '10px 20px',
        backgroundColor: '#333',
        color: '#fff',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        transition: 'opacity 0.3s, transform 0.3s'
      }}>
        {toastMessage}
      </div>
    )}</div>;
  } else if (kanbanData == [0]) {
    return <div style={{ color: 'black' }}>No Repair Orders found for this shop.        {toastMessage && (
      <div style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        padding: '10px 20px',
        backgroundColor: '#333',
        color: '#fff',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        transition: 'opacity 0.3s, transform 0.3s'
      }}>
        {toastMessage}
      </div>
    )}</div>;
  } else {


    return (
      <div>
        {toastMessage && (
          <div style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            padding: '10px 20px',
            backgroundColor: '#333',
            color: '#fff',
            borderRadius: '5px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
            transition: 'opacity 0.3s, transform 0.3s'
          }}>
            {toastMessage}
          </div>
        )}

        <div style={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '16px' }}>
          <FormControl variant="standard" size="small" style={{ marginBottom: '8px', width: '150px' }}>
            <InputLabel>View</InputLabel>
            <Select
              value={view}
              onChange={(e) => {setView(
                e.target.value
                )
                localStorage.setItem('kanbanview', e.target.value)
              }

              }
            >
              <MenuItem value={'kanban'}>Kanban View</MenuItem>
              <MenuItem value={'calendar'}>Calendar View</MenuItem>
              <MenuItem value={'list'}>List View</MenuItem>
            </Select>

          </FormControl>
          {view === 'kanban' && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
<LocalizationProvider dateAdapter={AdapterDayjs}>
  <DatePicker
    label="Filter by Vehicle Out Date"
    value={selectedDate}
    onChange={(newValue) => {
      setSelectedDate(newValue);
    }}
    componentsProps={{
      actionBar: {
        actions: ['clear'],
      },
    }}
  />

</LocalizationProvider>
<FormControl variant="outlined" size="small">
  <InputLabel id="selectInput-label">Filter by Flag</InputLabel>
  <Select
    labelId="selectInput-label"
    id="selectInput"
    value={selectedOption}
    onChange={(e) => setSelectedOption(e.target.value)}
    label="Filter by Flag"
  >
    <MenuItem value="None">No Flag Selected</MenuItem>
    <MenuItem value="allParts">All Parts</MenuItem>
    <MenuItem value="readyPaint">Ready Paint</MenuItem>
    <MenuItem value="allRepairNoParts">All Repair No Parts</MenuItem>
    <MenuItem value="hospital">Hospital</MenuItem>
    <MenuItem value="requireFrame">Require Frame</MenuItem>
    <MenuItem value="requireMech">Require Mech</MenuItem>
    <MenuItem value="requireGlass">Require Glass</MenuItem>
    <MenuItem value="needsCalibration">Needs Calibration</MenuItem>
  </Select>
</FormControl>
<TextField
  id="searchInput"
  label="Filter by RO #"
  variant="outlined"
  size="small"
  value={searchTextValue}
  onChange={(e) => setSearchTextValue(e.target.value)}
  onKeyPress={(e) => {
    if (e.key === 'Enter') {
      setSearchValue(searchTextValue);
      e.preventDefault(); // Prevent form submission
    }
  }}
/>
<IconButton onClick={() => setSearchValue(searchTextValue)}>
  <SearchIcon />
</IconButton>
</div>
)}
          {view !== 'kanban' ? (
            
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
  <FormControl variant="standard" size="small" style={{ marginRight: '20px', width: '150px' }}>
    <InputLabel>Filter By:</InputLabel>
    <Select
      value={dateField}
      onChange={(e) => setDateField(e.target.value)}
    >
      <MenuItem value={'startInDate'}>Start Date</MenuItem>
      <MenuItem value={'vehicleInDate'}>Vehicle In Date</MenuItem>
      <MenuItem value={'vehicleOutDate'}>Vehicle Out Date</MenuItem>
    </Select>
  </FormControl>

 

  <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
  <Typography variant="body2" style={{ marginRight: '10px',fontSize:12,marginTop:'1.25em' }}>Click to filter by stage:</Typography>
  
    {Object.entries(stageColorMapping).map(([stage, color]) => (
      <Button 
      key={stage} 
      onClick={() => {setStageField(stage)}}
      style={{
        textTransform:'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '10px',
        backgroundColor: color,
        padding: '3px 6px',
        borderRadius: '4px',
        color: getTextColor(color),
        fontSize: '0.8rem',
        height: '100%',
        marginTop:'1em'
      }}
    >
      {stage}
    </Button>
    ))}
     <Button 
    onClick={() => {setStageField('')}}
    style={{
      textTransform:'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '10px',
      backgroundColor: '#fffff2',
      padding: '3px 6px',
      borderRadius: '4px',
      color: 'black',
      fontSize: '0.8rem',
      height: '100%',
      marginTop:'1em'
    }}
  >
    Clear Filter
  </Button>
  </div>
</div>



          ) : null
}
{view === 'calendar' && (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
      <FlagIcon style={{ color: 'lightgreen', fontSize: '1rem' }} />
      <div>All Parts Here</div>
    </div>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FlagIcon style={{ color: 'orange', fontSize: '1rem' }} />
      <div>Ready for Paint</div>
    </div>
  </div>
)}
        </div>
        {view === 'kanban' ? (


          <KanbanBoard
            key={selectedShop}
            stages={stages}
            data={isFiltered ? filteredData : kanbanData}
            setData={setKanbanData}
            KanbanCard={RepairOrderCard}
            onChangeStage={onChangeStage}
            uid={uid}
            {...props}
            style={{ overflowY: 'scroll' }}
          />

        ) : (
          null
        )}

        {view === 'calendar' ? (<CalendarComponent kanbanData={kanbanData} dateField={dateField} />) : null}

        {view === 'list' ? (
          <RepairOrderList
            data={kanbanData}
            filter={dateField}
            stageFilter={stageField}
            onDataSelect={(id) => {
              window.open(`/#/repairorder/${id}/show/`, '_blank');
              // redirectTo(`/repairorder/${id}/show/`)
            }
            }
          />
        ) : null}
      </div>
    );
  }
};
export default AutomobileKanban;

