import apiClient from "./apiClient";
import jsonServerProvider from "ra-data-json-server";

import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';

const useNavigateRef = () => {
  const navigateRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  return navigateRef;
};


const jsonDataProvider = jsonServerProvider(
  "http://localhost:8000/api/v1"
);

const RESOURCES = {
  ONE_RO: "repairorder",
  REPAIR_ORDERS: "repairOrders",
  STAGES: "stages",
  UPDATE_STAGE: "updateStage",
  KANBAN: "kanban",
  USER_SETTINGS: "profile/",
  UPDATE_PROFILE: "updateProfile",
  SHOPS: "shopselect",
  SHOP_PRIVILEGES: "shop_privileges",
  CURRENT_USER: 'current_user',
  TIMECARD: 'timecards',

};


const authResponseCheck = async (response) => {
  try {
    if (response.status === 401) {
      return false;
    }
    else if(response.status === 403) {
      // Handle 403 Forbidden
      return false;
    }else{
      return true;
    }
  } catch (e) {
    console.log("Error: " + e);
    throw e; // Ensure the error is thrown so it can be caught by the caller
  }
};



const getList = async (list,params) => {
  try {
    if(list === RESOURCES.REPAIR_ORDERS){
      // console.log("Getting repair orders")
      // console.log(params)
      const response = await apiClient.get(list, {params});
      // console.log("fetching repair order response")
      // console.log(response.data)
      authResponseCheck(response)
      return response.data;
    }else if(list === RESOURCES.TIMECARD){
      const shopId = localStorage.getItem("selectedShopId");
      const url = `${list}?shop_id=${shopId}`;
      const response = await apiClient.get(url);
      authResponseCheck(response);
      return response.data;

    }else{



    // console.log("Getting list",list)
    const response = await apiClient.get(list);
    // console.log("fetching response", list)
    // console.log(response.data)
    authResponseCheck(response)
    return response.data;
    }
  } catch (error) {
    authResponseCheck(error.response)
    console.log("Error getting list")
    console.error(error);
    return [];
  }
};

const update = async (id, data,resource) => {

    if(resource === RESOURCES.UPDATE_PROFILE){
      try {
        // console.log("Trying to update profile", data)
        const response = await apiClient.patch(`profile/${id}/`, data);

        // Unwrapping one level
        authResponseCheck(response)
        return response.data;
      } catch (error) {
        authResponseCheck(error.response)
        console.error(error);
        if (error.response && error.response.status === 400) {
          // Handle validation error
          
          return Promise.reject({ message: 'Validation error! '+ error.response.data });
        } else {
          // Handle other errors
        }
      }

    }


  
};




const getUserSettings = async () => {
  try {
    const response = await apiClient.get(RESOURCES.USER_SETTINGS);
    authResponseCheck(response)
    return response.data;
  } catch (error) {
    authResponseCheck(error.response)
    console.error(error);
    console.log("Error getting user settings")
    return [];
  }
};

const updateUserSettings = async (data) => {
  try {
    const response = await apiClient.put(RESOURCES.USER_SETTINGS, data);
    authResponseCheck(response)
    return response.data;
  } catch (error) {
    authResponseCheck(error.response)
    console.error(error);
    console.log("Error updating user settings")
    return [];

  }
};


const dataProvider = {
  ...jsonDataProvider,

  create: async (resource, params) => {
    // console.log("doing create", resource, RESOURCES.USER_SETTINGS)
    if (resource == RESOURCES.USER_SETTINGS) {
      // console.log("Creating profile");
      const response = await apiClient.post(resource, params.data);
      authResponseCheck(response);
      // console.log("Response from create profile", response)
      return { data: response.data };
    }
  },

  getList: async (resource, params) => {
    // The kanban resource is actually just the repair orders resource
    // with a different name. We do this so that we can see the repair
    // orders in the kanban view and in the list view. But we want to
    // use the same data provider logic for both.


    // if (resource === RESOURCES.SHOPS){
    //   try {
    //     const response = await getList(resource);
    //     return response.data
    //   } catch (error) {
    //     console.error(error);
    //     return Promise.reject(error);
    //   }
    // }

    



    if (resource === RESOURCES.USER_SETTINGS) {
      try {
        const response = await getUserSettings();
        authResponseCheck(response)
        return Promise.resolve({
          data: response,
          total: response.length, // If it's an array; adjust if it's an object
        });
      } catch (error) {
        authResponseCheck(error.response)
        console.error(error);
        return Promise.reject(error);
      }
    } 

    
    if (resource === RESOURCES.KANBAN) {
      // console.log("resource is kanban", params)
      resource = RESOURCES.REPAIR_ORDERS;
    }

    if (resource === RESOURCES.REPAIR_ORDERS || resource === RESOURCES.STAGES || resource === RESOURCES.SHOPS) {
      try {
        // console.log("Trying to get list for"+resource)
        const response = await getList(resource,params);
        authResponseCheck(response)
        return Promise.resolve({
          data: response,
          total: response.length,
        });
      } catch (error) {
        authResponseCheck(error.response)
        console.error(error);
        return Promise.reject(error);
      }
    } else {
      // console.log("resource is " + resource)
      const response = await getList(resource,params);
      authResponseCheck(response)
        return Promise.resolve({
          data: response,
          total: response.length,
        });
    }
  },
  update: async (resource, params) => {
    try {
      const response = await update(params.id, params.data, resource);
      await authResponseCheck(response)
      return Promise.resolve(response);
    } catch (error) {
      await authResponseCheck(error.response)
      console.error(error);
      return Promise.reject(error);
    }
  },

  getOne: async (resource, params) => {
    // console.log("Doing getOne", resource, params)
    // console.log(resource, RESOURCES.USER_SETTINGS)
    if (resource == RESOURCES.USER_SETTINGS) {
      try {
        const response = await apiClient.get(`${RESOURCES.USER_SETTINGS}${params.id}/`);
        authResponseCheck(response)
        return { data: response.data };
      } catch (error) {
        authResponseCheck(error.response)
        console.error(error);
        return Promise.reject(error);
      }
    } else if(resource === RESOURCES.SHOPS){
      try {
        const response = await apiClient.get(`${RESOURCES.SHOPS}/${params.id}/`);
        authResponseCheck(response)
        return { data: response.data };
      } catch (error) {
        authResponseCheck(error.response)
        console.error(error);
        return Promise.reject(error);
      }

    }
  //   else if (RESOURCES.SHOPS){
  //     try {
  //       console.log("Trying to get one shop")
  //       const response = await apiClient.get(`${RESOURCES.SHOPS}/${params.id}/`);
  //       return { data: response.data };
  //     } catch (error) {
  //       console.error(error);
  //       return Promise.reject(error);
  //     }
  // }
  else if(resource===RESOURCES.ONE_RO){
    try {
      const response = await apiClient.get(`${RESOURCES.ONE_RO}/${params.id}/`);
      authResponseCheck(response)
      return { data: response.data };
    } catch (error) {
      authResponseCheck(error.response)
      console.error(error);
      return Promise.reject(error);
    }
  }else if(resource===RESOURCES.TIMECARD){
    try {
    const response = await apiClient.get(`${RESOURCES.TIMECARD}/${params.id}/`);
    authResponseCheck(response)
    return { data: response.data };
  } catch (error) {
    authResponseCheck(error.response)
    console.error(error);
    return Promise.reject(error);
  }
  }
   else {
      // console.log("Else from getone")
      // For other resources, fallback to the default behavior
      const response = jsonDataProvider.getOne(resource, params);
      authResponseCheck(response)
      return { data: response.data };
    }
  },

};
export default dataProvider;