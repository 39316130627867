import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import SecondFeaturedPost from './SecondFeaturedPost';
import ThirdFeaturedPost from './ThirdFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import Footer from './Footer';
import post1 from './blog-post.1.md';
import post2 from './blog-post.2.md';
import post3 from './blog-post.3.md';
import highway from '../highway.jpg';
import stopwatch from '../stopwatch.jpg';
import hotrod from '../hotrod.jpg';
import businessman from '../businessman.jpg';
import contact from '../contact.jpg';


const sections = [
  { title: 'Why Use Revv Production?', url: '#why_revv'},
  { title: 'Upgrade Your Facility', url: '#upgrade' },
  { title: 'About Us', url: '#about' },
  { title: 'Contact Us', url: '#contact' },
  { title: 'Customer Login', url: '/login' },
  // { title: 'Pricing', url: '#' },
  // { title: 'Our Customers', url: '#' },
  // { title: 'Resources', url: '#' },
];

const mainFeaturedPost = {
  title: 'Revv your engines, drive higher profitability',
  description:
    "With Revv Production you can streamline your collision repair workflow, and get your customers back on the road faster.",
  image: highway,
  imageText: '',
  linkText: ''

};

const secondFeaturedPost = {
  title: 'Make every second count',
  description:
    "With Revv Production, your shop becomes an interconnected digital ecosystem. Take back your time and get back to what you do best.",
  image: stopwatch,
  imageText: '',
  linkText: ''
};

const thirdFeaturedPost = {
  title: 'Time for an upgrade?',
  description:
    "Work smarter, not harder. Reduce waste and multiply your profit margin.",
  image: hotrod,
  imageText: '',
  linkText: ''
};

const fourthFeaturedPost = {
  title: 'Who are we?',
  description:
    "With our flagship management platform Revv Production, we have distilled over 30 years of hard-earned shop management experience into a simple, easy-to-use tool that will help you run your shop like a finely tuned machine.",
  image: businessman,
  imageText: '',
  linkText: ''
}
  const fifthFeaturedPost = {
    title: 'Find out more',
    description:
      "Click here for a free consultation and learn more about our revolutionary management platform.",
    image: contact,
    imageText: '',
    linkText: ''

};

const handleClick = () => {
  // console.log('clicked')

}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Blog() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Header title="Revv Production" sections={sections} sx={{boxShadow: "1px 3px 1px #9E9E9E"}} maxWidth={false}/>
      <Container maxWidth={false}>
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          <section id="why_revv">
          <SecondFeaturedPost post={secondFeaturedPost}  />
          </section>
          <section id ="upgrade"  >
          <ThirdFeaturedPost post={thirdFeaturedPost} />
          </section>
          <section id="about">
          <SecondFeaturedPost post={fourthFeaturedPost}  />
          </section>
          <section id="contact" >
          <ThirdFeaturedPost post={fifthFeaturedPost} button={true} />
          </section>
          <Grid container spacing={5} sx={{ mt: 3 }}>
            
          </Grid>
        </main>
      </Container>
      <Footer
        title="Revv Production"
        description="Run your shop like a finely tuned machine."
      />
    </ThemeProvider>
  );
}
