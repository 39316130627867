import { Show, SimpleShowLayout, TextField, DateField, NumberField, SelectInput,useDataProvider,useRefresh } from 'react-admin';
import { Grid, styled, Typography, Box,Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Text } from 'react';
import { grey } from '@mui/material/colors';

import { Dialog, Modal,Button, Switch, FormControlLabel,DialogActions, DialogContent, DialogTitle, Card, CardContent, Input, IconButton, Tooltip } from "@mui/material";

import FlagIcon from "@mui/icons-material/Flag";

import React, { useState, useEffect } from "react";
import "./style.css";
import apiClient from "apiClient";
import { useShowController } from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs from 'dayjs';









const StyledShowLayout = styled(SimpleShowLayout)({
    padding: '16px',
    backgroundColor: grey[100],
  });
  
  const StyledGridContainer = styled(Grid)({
    marginTop: '16px',
  });
  
  const StyledGridItem = styled(Grid)({
    padding: '16px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
  });
  
  const FieldWithLabel = ({ label, children }) => (
    <Box mb={2}>
      <Typography variant="h6" gutterBottom>{label}</Typography>
      {children}
    </Box>
  );
  
  const StandaloneEditableField = ({ record, source, onSave,stages }) => {


    console.log("stages",stages)
    const [value, setValue] = useState(record[source]); 
    const [isEditing, setIsEditing] = useState(false);

  
    const handleSelectChange = (event) => {
      const selectedStageId = event.target.value;
      const selectedStage = stages.find(stage => stage.id === selectedStageId);
      setValue(selectedStage);
    };
  
    const handleSave = async () => {
      setIsEditing(false); // Move this inside try if you want to revert on failure
      const affectedCards = [{ id: record.id, stage: value.id }]; // Assuming this is the desired structure
      const primary = true; // Adapt as needed
      let data = { cards: affectedCards, session: Math.random().toString(36).substring(2) + Date.now().toString(36), changeType: "stage", primary: primary };
  
      try {
        const response = await apiClient.patch(`bulk_update/`, data);
        console.log("Update successful", response.data);
        if(onSave) {
          onSave(); // Assuming `onSave` will handle refreshing or updating the parent component state
        }
        
      } catch (error) {
        console.error("Failed to update stage", error);
        // Optionally revert editing or notify user
      }
    };

    return (
      <div><Typography sx={{ fontWeight: 'bold', marginLeft: 2 }}>Current Stage:</Typography>
        {isEditing ? (
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="stage-select-label">Stage</InputLabel>
            <Select
              labelId="stage-select-label"
              value={value.id}
              onChange={handleSelectChange}
              label="Stage"
            >
              {stages.map((stage) => (
                <MenuItem key={stage.id} value={stage.id}>{stage.name}</MenuItem>
              ))}
            </Select>
            <Button onClick={handleSave} color="primary" size="large">
              Save
            </Button>
          </FormControl>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{marginLeft:2 }}>{value.name}</Typography>
            <IconButton onClick={() => setIsEditing(true)} size="large">
              <EditIcon />
            </IconButton>
          </div>
        )}
      </div>
    );
  };




  const RepairOrderShow = (props) => {
    const { record, loading, error } = useShowController(props);


    //log startInDate from record

    console.log("record",record)
    //check if record.startInDate exists then log it if so
    if(record!=undefined){
      if(record.startInDate!=undefined){
        console.log("record.startInDate",record.startInDate)
      }
    }

    const [stages, setStages] = useState([]);
    const dataProvider=useDataProvider()
    const refresh = useRefresh();
    useEffect(() => {
 
      dataProvider.getList("stages", {})
          .then(({ data }) => {
              setStages(data);
              console.log("data", data);
          });
  }, []); // Empty dependency array ensures this runs once on mount



    const [flagOpen, setFlagOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalUsers, setModalUsers] = useState([]);
    
  const [dateValue, setDateValue] = useState(record ? record.startInDate : null);
  const [originalDate, setOriginalDate] = useState(record ? record.startInDate : null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
      console.log("props",props)

      console.log("record",record)
      // Now you can use `record` in place of `cardData`
      const [toggleState, setToggleState] = useState({});

      useEffect(() => {
        if (record && record.startInDate) {
          const parsedDate = dayjs(record.startInDate, 'MM-DD-YYYY');
          setDateValue(parsedDate);
          setOriginalDate(parsedDate);
        }
      }, [record]);

      const updateStartInDate = async (newDate) => {
        console.log(dateValue)
        setOriginalDate(dateValue);
        setDateValue(newDate);
    
        const updatedRepairOrder = { ...record, startInDate: newDate.format('MM-DD-YYYY') };
        
        try {
            const payload = {
                startInDate: newDate.format('MM-DD-YYYY'),
                // session: uid,
                changeType: "start_date"
            };
    
            const response = await apiClient.patch(`/repairOrders/${record.id}/`, payload);
    
            if (response.status !== 200) {
                console.error("Error updating the startInDate");
                setDateValue(originalDate);
                throw new Error("Error updating the startInDate");
            } else {
                // Update the record with the new startInDate
                record.startInDate = newDate.format('MM-DD-YYYY');
            }
        } catch (error) {
            console.error("There was an error updating the startInDate:", error);
            setDateValue(originalDate);
            alert("The date was invalid. Please try again.");
        }
    };
    

    

      useEffect(() => {
        if (record) {
          setToggleState({
            allParts: record.allParts || false,
            readyPaint: record.readyPaint || false,
            allRepairNoParts: record.allRepairNoParts || false,
            hospital: record.hospital || false,
            requireFrame: record.requireFrame || false,
            requireMech: record.requireMech || false,
            requireGlass: record.requireGlass || false,
            needsCalibration: record.needsCalibration || false,
          });
        }
      }, [record]);
    
      if (loading) return <div>Loading...</div>;
      if (error) return <div>Error: {error.message}</div>;
      if (!record) return null;

      const onSaveSuccess = (updatedRecord) => {
        // Update the state with the new record details
        refresh();
      };



      const handleToggleChange = (event) => {
        setToggleState({
          ...toggleState,
          [event.target.name]: event.target.checked,
        });
      };



      const flagWidth = 24
      const flagHeight = 8
      
      const handleEditFlag = () => {
        // console.log("edit flag")
        setFlagOpen(true)
      }
      
      const handleClose = () => {
        setFlagOpen(false)
      }
      
      const handleSave = async () => {
        const payload = {
          allParts: toggleState.allParts,
          readyPaint: toggleState.readyPaint,
          allRepairNoParts: toggleState.allRepairNoParts,
          hospital: toggleState.hospital,
          requireFrame: toggleState.requireFrame,
          requireMech: toggleState.requireMech,
          requireGlass: toggleState.requireGlass,
          needsCalibration: toggleState.needsCalibration,
          // session: uid,
          changeType: 'flag'
          // ... any other fields you may want to update
        };
        
        try {
          const response = await apiClient.patch(`/repairOrders/${record.id}/`, payload);
          // console.log(response)
          if (response.status === 200) {
            setFlagOpen(false)

          } else {
            // Handle non-200 responses
            alert('Failed to update. Please try again.');
          }
        } catch (error) {
          console.log(error)
          // Handle errors - network issues, server down, etc
          alert('An error occurred. Please try again.');
        }
        
        
      } 


      const DollarField = (source) => {
        console.log("source",source)
        console.log("record",record)
        console.log("record[source]",record[source])
        const valueInDollars = parseInt(record[source["source"]]) / 100;
        console.log("valueInDollars",valueInDollars)
        return <span>${valueInDollars.toFixed(2)}</span>;
      };


      const stageColorMapping = {
        "Repair Planning": "#FAE9B0", // Replace with actual stage names and colors
        "Waiting to Start": "#F1AE6A",
        "Frame": "#CB4C4F",
        "Mechanical": "#B66C80",
        "Body": "#895B96",
        "Refinish": "#709FC1",
        "Assembly": "#90B270",
        "Detail": "#5F8356",
        "Ready for Delivery": "#202424",
        // Add more stages as needed...
      };
      const flagData = {
        allParts: { color: 'lightgreen', name: 'All Parts Here' },
        readyPaint: { color: 'orange', name: 'Ready for Paint' },
        allRepairNoParts: { color: 'blue', name: 'All Repairs - no parts' },
        hospital: { color: 'red', name: 'HOSPITAL' },
        requireFrame: { color: 'black', name: 'Requires Frame' },
        requireMech: { color: 'pink', name: 'Requires Mechanical' },
        requireGlass: { color: 'grey', name: 'Requires Glass' },
        needsCalibration: { color: 'purple', name: 'Needs Calibration' },
        // Add more flags as needed...
      };
      const getTextColor = (backgroundColor) => {
        return darkColors.includes(backgroundColor) ? '#fff' : '#000';
      };
      const darkColors = ['black',  /* other dark colors */];
      const toCamelCase = (str) => {
        return str.split(' ').map((word, index) => {
          if (index === 0) {
            return word.toLowerCase();
          }
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }).join('');
      };
      {Object.entries(flagData).map(([flag, data]) => (
        toggleState[flag] ? (
          <Tooltip key={flag} title={data.name}>
            <Box bgcolor={data.color} sx={{ width: flagWidth, height: flagHeight, marginRight: 1 }}>
              <Typography style={{ color: getTextColor(data.color) }}>
                {data.name}
              </Typography>
            </Box>
          </Tooltip>
        ) : null
      ))}



    if(record!=undefined){
      
      return(
      <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Show {...props}>
      
            <Box sx={{width:'100%'}}>
            <Typography sx={{ fontWeight: 'bold', marginLeft: 2 }}>Active flags:</Typography>
            <Box display="flex" justifyContent="flex-start" sx={{marginBottom:1}} >
        
            <Tooltip title="Edit Labels">
            <IconButton size="small" onClick={handleEditFlag} >
            <FlagIcon sx={{ alignSelf:'center',marginLeft: 1 }}/>
            
            </IconButton>
            </Tooltip>
 
            <div style={{ display: "flex", alignItems: 'center', height: '100%' }}>
              {Object.entries(flagData).map(([flag, data]) => (
                toggleState[flag] ? (
                  <Tooltip key={flag} title={data.name}>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '10px',
                      backgroundColor: data.color,
                      padding: '3px 6px',
                      borderRadius: '4px',
                      color: getTextColor(data.color),
                      fontSize: '0.8rem',
                      height: '100%',
                      marginTop:'1em'
                    }}>
                      {data.name}
                    </div>
                  </Tooltip>
                ) : null
              ))}

            </div>

            <Dialog open={flagOpen} onClose={handleEditFlag} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Repair Order {record.ro} Labels</DialogTitle>
        <DialogContent>
      <FormControlLabel
        control={
          <Switch
            checked={toggleState.allParts}
            onChange={handleToggleChange}
            name="allParts"
          />
        }
        label="All Parts"
      />
      <FormControlLabel
        control={
          <Switch
            checked={toggleState.readyPaint}
            onChange={handleToggleChange}
            name="readyPaint"
          />
        }
        label="Ready for Paint"
      />
      <FormControlLabel
        control={
          <Switch
            checked={toggleState.allRepairNoParts}
            onChange={handleToggleChange}
            name="allRepairNoParts"
          />
        }
        label="All Repair, No Parts"
      />
      <FormControlLabel
        control={
          <Switch
            checked={toggleState.hospital}
            onChange={handleToggleChange}
            name="hospital"
          />
        }
        label="Hospital"
      />
      <FormControlLabel
        control={
          <Switch
            checked={toggleState.requireFrame}
            onChange={handleToggleChange}
            name="requireFrame"
          />
        }
        label="Requires Frame"
      />
      <FormControlLabel
        control={
          <Switch
            checked={toggleState.requireMech}
            onChange={handleToggleChange}
            name="requireMech"
          />
        }
        label="Requires Mechanic"
      />
      <FormControlLabel
        control={
          <Switch
            checked={toggleState.requireGlass}
            onChange={handleToggleChange}
            name="requireGlass"
          />
        }
        label="Requires Glass"
      />
      <FormControlLabel
        control={
          <Switch
            checked={toggleState.needsCalibration}
            onChange={handleToggleChange}
            name="needsCalibration"/>
        }
        label="Needs Calibration"
          />
    </DialogContent>
        <DialogActions>

          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>


          </Box>
        </Box>
        <Box>
        <div style={{ maxWidth: '400px' }}>
            <StandaloneEditableField
  record={record}
  source="stage_detail"
  stages={stages}
  handleSave={onSaveSuccess}

/></div>
</Box>
      <StyledShowLayout>
        <StyledGridContainer container spacing={2}>
          <StyledGridItem item xs={12} sm={6} md={4}>
            <FieldWithLabel label="RO Number"><TextField source="ro" /></FieldWithLabel>
            <FieldWithLabel label="Vehicle In Date"><DateField source="vehicleInDate" /></FieldWithLabel>
            <FieldWithLabel label="Vehicle Out Date"><DateField source="vehicleOutDate" /></FieldWithLabel>
            <StyledGridItem item xs={12} sm={6} md={4}>
    <FieldWithLabel label="Start Date">
        <DatePicker
            value={dateValue}
            onChange={(newDate) => updateStartInDate(newDate)}
            format="MM/DD/YYYY"
            open={isDatePickerOpen}
            onOpen={() => setIsDatePickerOpen(true)}
            onClose={() => setIsDatePickerOpen(false)}
            TextFieldComponent={() => (
                <Typography variant="body2" gutterBottom onClick={() => setIsDatePickerOpen(true)}>
                    {dateValue.format('MM/DD/YYYY')}
                </Typography>
            )}
        />
    </FieldWithLabel>
</StyledGridItem>

            <FieldWithLabel label="Total Hours"><NumberField source="totalHours" /></FieldWithLabel>
            <FieldWithLabel label="Price"><DollarField source="priceInCents" /></FieldWithLabel>
<FieldWithLabel label="Sales Tax Total"><DollarField source="salesTaxTotalInCents" /></FieldWithLabel>
 </StyledGridItem>
          <StyledGridItem item xs={12} sm={6} md={4}>
            <FieldWithLabel label="Status"><TextField source="status" /></FieldWithLabel>
            <FieldWithLabel label="Pipeline"><TextField source="pipeline.name" /></FieldWithLabel>
            <FieldWithLabel label="Shop"><TextField source="shop.name" /></FieldWithLabel>
            <FieldWithLabel label="Created"><DateField source="created" /></FieldWithLabel>
            <FieldWithLabel label="Modified"><DateField source="modified" /></FieldWithLabel>
            <FieldWithLabel label="Is Closed"><TextField source="isClosed" /></FieldWithLabel>
          </StyledGridItem>
          <StyledGridItem item xs={12} sm={6} md={4}>
            <FieldWithLabel label="Insurance Company"><TextField source="insuranceCompany" /></FieldWithLabel>
            <FieldWithLabel label="VIN"><TextField source="vin" /></FieldWithLabel>
            <FieldWithLabel label="Vehicle Year"><NumberField source="vehicleYear" /></FieldWithLabel>
            <FieldWithLabel label="Vehicle Make"><TextField source="vehicleMake" /></FieldWithLabel>
            <FieldWithLabel label="Vehicle Model"><TextField source="vehicleModel" /></FieldWithLabel>
            <FieldWithLabel label="Vehicle Body Style"><TextField source="vehicleBodyStyle" /></FieldWithLabel>
            <FieldWithLabel label="Vehicle Trim Package"><TextField source="vehicleTrimPackage" /></FieldWithLabel>
            <FieldWithLabel label="Vehicle Color"><TextField source="vehicleColor" /></FieldWithLabel>
            <FieldWithLabel label="Vehicle Interior Color"><TextField source="vehicleInteriorColor" /></FieldWithLabel>
            <FieldWithLabel label="Vehicle Paint Code"><TextField source="vehiclePaintCode" /></FieldWithLabel>
            <FieldWithLabel label="Vehicle Trim Code"><TextField source="vehicleTrimCode" /></FieldWithLabel>
            <FieldWithLabel label="Vehicle Production Date"><DateField source="vehicleProductionDate" /></FieldWithLabel>
            <FieldWithLabel label="Vehicle Mileage In"><NumberField source="vehicleMileageIn" /></FieldWithLabel>
            <FieldWithLabel label="Customer Name"><TextField source="customerName" /></FieldWithLabel>
            <FieldWithLabel label="Estimator Name"><TextField source="estimatorName" /></FieldWithLabel>
            <FieldWithLabel label="Estimator ID"><TextField source="estimatorId" /></FieldWithLabel>
          </StyledGridItem>
        </StyledGridContainer>
      </StyledShowLayout>
    </Show>
    </LocalizationProvider>
  );}else{
    return(<div>Loading...</div>)
  }
      }
  export default RepairOrderShow;

  