import { Dialog, Modal,Button, Switch, FormControlLabel,DialogActions, DialogContent, DialogTitle, Typography, Card, CardContent, Box, Input, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import FlagIcon from "@mui/icons-material/Flag";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import React, { useState, useEffect } from "react";
import "./style.css";
import apiClient from "apiClient";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import dayjs from 'dayjs';
import { Form } from "react-admin";



const RepairOrderCard = React.memo(({ cardData,uid, setData }) => {

  

  const [isEditing, setIsEditing] = useState(false);
  const [dateValue, setDateValue] = useState(dayjs(cardData.startInDate));
  const [originalDate, setOriginalDate] = useState(dayjs(cardData.startInDate));
  
  const [flagOpen, setFlagOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalUsers, setModalUsers] = useState([]);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  // const [play, setPlay] = useState(cardData.times.length>0 ? cardData.times[0].paused ? false : true : false);
  // const [pause, setPause] = useState(false);
  const [toggleState, setToggleState] = useState({
    allParts: cardData.allParts || false,
    readyPaint: cardData.readyPaint || false,
    allRepairNoParts: cardData.allRepairNoParts || false,
    hospital: cardData.hospital || false,
    requireFrame: cardData.requireFrame || false,
    requireMech: cardData.requireMech || false,
    requireGlass: cardData.requireGlass || false,
    needsCalibration: cardData.needsCalibration || false,
  });
  // console.log("Times", cardData)
  // if(cardData.times.length>0){
  // console.log("Times+++++++++++++++++",cardData.times[0])}

  const [currentTimeForStage, setCurrentTimeForStage] = useState(cardData.times.find(time => time.stage === cardData.stage) || null);
  const isComplete = currentTimeForStage ? currentTimeForStage.complete : false;
  const isStarted = currentTimeForStage ? currentTimeForStage.started : false;
  const isPaused = currentTimeForStage ? currentTimeForStage.paused : false;

  


  const [play, setPlay] = useState(!isComplete && isStarted);
  const [pause, setPause] = useState(!isComplete && isPaused);
  const [complete, setComplete] = useState(isComplete);




const CustomLink = ({ to, children }) => (
  <a href={to} target="_blank" rel="noopener noreferrer"   style={{color:'black',fontWeight:'bold'}} >
    {children}
  </a>
);

useEffect(() => {
  // Handle date changes
  if(dateValue !== dayjs(cardData.startInDate) && !isEditing){
      setDateValue(dayjs(cardData.startInDate));
      setOriginalDate(dayjs(cardData.startInDate));
  }
}, [cardData.startInDate, dateValue, isEditing]);

useEffect(() => {
  // Handle play/pause/complete states
  // console.log("Updating card state");
  // console.log(cardData);
  // console.log("Repair order card",uid)
  const newCurrentTimeForStage = cardData.times.find(time => time.stage === cardData.stage);
  setCurrentTimeForStage(newCurrentTimeForStage);

  if (newCurrentTimeForStage) {
      const isComplete = newCurrentTimeForStage.complete;
      setPlay(newCurrentTimeForStage.started);
      if(newCurrentTimeForStage.started){//console.log("STARTED")
      }
      setPause(newCurrentTimeForStage.paused);
      if(newCurrentTimeForStage.paused){//console.log("PAUSED")
      }
      setComplete(isComplete);
      if(isComplete){//console.log("COMPLETE")
      }
  } else {
      setPlay(false);
      setPause(false);
      setComplete(false);
      // console.log("Setting everything false, no currenttimeforstage")
  }
}, [cardData.times]);

useEffect(() => {
  
  setToggleState({
    allParts: cardData.allParts || false,
    readyPaint: cardData.readyPaint || false,
    allRepairNoParts: cardData.allRepairNoParts || false,
    hospital: cardData.hospital || false,
    requireFrame: cardData.requireFrame || false,
    requireMech: cardData.requireMech || false,
    requireGlass: cardData.requireGlass || false,
    needsCalibration: cardData.needsCalibration || false,
  });
}, [cardData]);




  const sosButton = async (event) => {
    
    try {
      // console.log("Getting user list for this shop: ", cardData.shop.id)
      const response = await apiClient.get(`/sos/`, { params: { shop: cardData.shop.id } });

      // console.log(response.data)
      // console.log(response.data.data)
      const users = await response.data.data
      // console.log(users)
      setModalUsers(users)
      setShowModal(true)
    }catch (error) {
          console.error("SOS Error:", error);
      }
  }


  const handleToggleChange = (event) => {
    setToggleState({
      ...toggleState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleInputChange = (e) => {
    setDateValue(e.target.value);
  };

  const updateStartInDate = async (newDate) => {
    // Optimistically update the local state
    setOriginalDate(dateValue);

    // Optimistically update the parent's state
    const updatedRepairOrder = { ...cardData, startInDate: newDate.format('MM-DD-YYYY') };
    setData(prevKanbanData => {
        return prevKanbanData.map(item => {
            if (item.id === updatedRepairOrder.id) {
                return updatedRepairOrder;
            }
            return item;
        });
    });

    try {
        const payload = {
          startInDate: newDate.format('MM-DD-YYYY'),
            session: uid,
            changeType: "start_date"
        };
        
        const response = await apiClient.patch(`/repairOrders/${cardData.id}/`, payload);

        if (response.status !== 200) {
            console.error("Error updating the startInDate");
            setDateValue(newDate);
            throw new Error("Error updating the startInDate");
        }
    } catch (error) {
        console.error("There was an error updating the startInDate:", error);
        
        // Revert the optimistic updates
        setDateValue(originalDate); // Revert the local state
        setData(prevKanbanData => {  // Revert the parent's state
            return prevKanbanData.map(item => {
                if (item.id === cardData.id) {
                    return { ...item, startInDate: originalDate };
                }
                return item;
            });
        });

        alert("The date was invalid. Please try again.");
    }
};



const flagWidth = 24
const flagHeight = 8

const handleEditFlag = () => {
  // console.log("edit flag")
  setFlagOpen(true)
}

const handleClose = () => {
  setFlagOpen(false)
}

const handleSave = async () => {
  const payload = {
    allParts: toggleState.allParts,
    readyPaint: toggleState.readyPaint,
    allRepairNoParts: toggleState.allRepairNoParts,
    hospital: toggleState.hospital,
    requireFrame: toggleState.requireFrame,
    requireMech: toggleState.requireMech,
    requireGlass: toggleState.requireGlass,
    needsCalibration: toggleState.needsCalibration,
    session: uid,
    changeType: 'flag'
    // ... any other fields you may want to update
  };
  
  try {
    const response = await apiClient.patch(`/repairOrders/${cardData.id}/`, payload);
    // console.log(response)
    if (response.status === 200) {
      setFlagOpen(false)
      setData(prevKanbanData => {
        return prevKanbanData.map(item => {
            if (item.id === cardData.id) {
                return {...item, ...payload};
            }
            return item;
        });
      });
    } else {
      // Handle non-200 responses
      alert('Failed to update. Please try again.');
    }
  } catch (error) {
    console.log(error)
    // Handle errors - network issues, server down, etc
    alert('An error occurred. Please try again.');
  }
  
  
} 


const handleSubmit = async (formData) => {
  try {
      const response = await apiClient.post('/sos/', formData);

      if (response.status === 200) {
          // console.log("SOS sent successfully!");
          setShowModal(false);
          // Handle success, maybe close the modal or show a success message
      } else {
          console.error("Error sending SOS:", response);
          // Handle error, maybe show an error message to the user
      }
  } catch (error) {
      console.error("Error sending SOS:", error);
      // Handle error, maybe show an error message to the user
  }
};

const handleFormSubmit = (e) => {
  e.preventDefault();

  const formData = {
      users: Array.from(e.target.elements.selectedUser)
          .filter(checkbox => checkbox.checked)
          .map(checkbox => checkbox.value),
      message: e.target.elements.message.value,
      ro: cardData.ro,
  };

  handleSubmit(formData);
};


const repairOrderTimeCall = async (action) => {
  const payload = {
    repairOrder: cardData.id,
    stage: cardData.stage,
    action: action,
    changeType: 'time',
    session: uid,
  };

  if (currentTimeForStage) {
    payload.id = currentTimeForStage.id;
  }

  if (action === "start") {
    payload.startTime = new Date().toISOString();
    payload.started = true;
    payload.paused = false;
    payload.complete = false;
  } else if (action === "pause") {
    payload.paused = true;
    payload.currentPauseStart = new Date().toISOString();
    payload.started = false;
    payload.complete = false;
  } else if (action === "stop") {
    payload.endTime = new Date().toISOString();
    payload.complete = true;
    payload.started = false;
    payload.paused = false;
  }

  // Optimistically update the local state
  const updatedTime = {
    id: payload.id || null,
    stage: cardData.stage,
    started: payload.started,
    paused: payload.paused,
    complete: payload.complete
  };

    const updatedTimes = cardData.times.map(time => {
      if (time.stage === cardData.stage) {
        return updatedTime;
      }
      return time;
    });

    if (!updatedTimes.some(time => time.stage === cardData.stage)) {
      updatedTimes.push(updatedTime);
    }

    const updatedCardData = { ...cardData, times: updatedTimes };

    // Update the parent's state with the updated times array
    setData(prevKanbanData => {
      return prevKanbanData.map(item => {
        if (item.id === cardData.id) {
          return updatedCardData;
        }
        return item;
      });
    });

    try {
      let response;
      if (payload.id) {
        response = await apiClient.patch(`/repairOrderTimes/${payload.id}/`, payload);
      } else {
        response = await apiClient.post(`/repairOrderTimes/`, payload);
      }

      if (response.status !== 200) {
        throw new Error("Failed to update the time data.");
      }

    } catch (error) {
      console.error(error);
      alert('An error occurred. Please try again.');

      // If there's an error, revert the changes in the parent's state
      setData(prevKanbanData => {
        return prevKanbanData.map(item => {
          if (item.id === cardData.id) {
            return cardData; // revert to the original cardData
          }
          return item;
        });
      });
    }
  }


    return (
  <Card 
    variant="outlined" 
    sx={{ 
      width: "100%", 
      marginBottom: 0, 
      height: '250px', 
      overflow: 'hidden', 
      backgroundColor: dayjs().format('YYYY-MM-DD') < dayjs(cardData.vehicleOutDate, 'MM-DD-YYYY').format('YYYY-MM-DD') ? 'white' : 
                       dayjs().format('YYYY-MM-DD') === dayjs(cardData.vehicleOutDate, 'MM-DD-YYYY').format('YYYY-MM-DD') ? '#87D06C' : '#EE6A53'
    }}
  >

        <CardContent>
          <div style={{ display: "flex" }}>
          {toggleState.allParts ? (<Tooltip title="All Parts Here"><Box bgcolor={'lightgreen'} sx={{ width: flagWidth, height: flagHeight, marginRight: 1 }}></Box></Tooltip>) : (null) }
          {toggleState.readyPaint ? (<Tooltip title="Ready for Paint"><Box bgcolor={'orange'} sx={{ width: flagWidth, height: flagHeight, marginRight: 1 }}></Box></Tooltip>) : (null) }
          {toggleState.allRepairNoParts ? (<Tooltip title="All Repairs - no parts"><Box bgcolor={'blue'} sx={{ width: flagWidth, height: flagHeight, marginRight: 1 }}></Box></Tooltip>) : (null) }
          {toggleState.hospital ? (<Tooltip title="HOSPITAL"><Box bgcolor={'red'} sx={{ width: flagWidth, height: flagHeight , marginRight: 1 }}></Box></Tooltip>) : (null) }
          {toggleState.requireFrame ? (<Tooltip title="Requires Frame"><Box bgcolor={'black'} sx={{ width: flagWidth, height: flagHeight, marginRight: 1 }}></Box></Tooltip>) : (null) }
          {toggleState.requireMech ? (<Tooltip title="Requires Mechanical"><Box bgcolor={'pink'} sx={{ width: flagWidth, height: flagHeight, marginRight: 1 }}></Box></Tooltip>) : (null) }
          {toggleState.requireGlass ? (<Tooltip title="Requires Glass"><Box bgcolor={'grey'} sx={{ width: flagWidth, height: flagHeight, marginRight: 1 }}></Box></Tooltip>) : (null) }
          {toggleState.needsCalibration ? (<Tooltip title="Needs Calibration"><Box bgcolor={'purple'} sx={{ width: flagWidth, height: flagHeight, marginRight: 1 }}></Box></Tooltip>) : (null) }
        
        </div>
        <Box display="flex" flexDirection="row" justifyContent="space-between" flexWrap="wrap">
          <Box sx={{width:'100%'}}>
            <Box display="flex" justifyContent="space-between" sx={{marginBottom:1}} >
            <Typography variant="h6"  component={CustomLink} to={`/#/repairorder/${cardData.id}/show/`} sx={{color:'black',fontWeight:'bold'}}   >
              RO #: {cardData.ro}
            </Typography>
            <Tooltip title="Edit Labels">
            <IconButton size="small" onClick={handleEditFlag} >
            <FlagIcon sx={{ alignSelf:'center',marginLeft: 1 }}/>
            </IconButton>
            </Tooltip>

            <Tooltip title="Report SOS">
<IconButton
    // disabled={complete}
    color="error"
    sx={{opacity:0.5,"&:hover": {
      color: "red",
      opacity: 1,
    },}}
    onClick={sosButton}
>
    <ReportProblemIcon />
</IconButton>
</Tooltip>
            <Dialog open={flagOpen} onClose={handleEditFlag} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Repair Order {cardData.ro} Labels</DialogTitle>
        <DialogContent>
      <FormControlLabel
        control={
          <Switch
            checked={toggleState.allParts}
            onChange={handleToggleChange}
            name="allParts"
          />
        }
        label="All Parts"
      />
      <FormControlLabel
        control={
          <Switch
            checked={toggleState.readyPaint}
            onChange={handleToggleChange}
            name="readyPaint"
          />
        }
        label="Ready for Paint"
      />
      <FormControlLabel
        control={
          <Switch
            checked={toggleState.allRepairNoParts}
            onChange={handleToggleChange}
            name="allRepairNoParts"
          />
        }
        label="All Repair, No Parts"
      />
      <FormControlLabel
        control={
          <Switch
            checked={toggleState.hospital}
            onChange={handleToggleChange}
            name="hospital"
          />
        }
        label="Hospital"
      />
      <FormControlLabel
        control={
          <Switch
            checked={toggleState.requireFrame}
            onChange={handleToggleChange}
            name="requireFrame"
          />
        }
        label="Requires Frame"
      />
      <FormControlLabel
        control={
          <Switch
            checked={toggleState.requireMech}
            onChange={handleToggleChange}
            name="requireMech"
          />
        }
        label="Requires Mechanic"
      />
      <FormControlLabel
        control={
          <Switch
            checked={toggleState.requireGlass}
            onChange={handleToggleChange}
            name="requireGlass"
          />
        }
        label="Requires Glass"
      />
      <FormControlLabel
        control={
          <Switch
            checked={toggleState.needsCalibration}
            onChange={handleToggleChange}
            name="needsCalibration"
          />
        }
        label="Needs Calibration"
      />
    </DialogContent>
        <DialogActions>

          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>



            </Box>
            <Typography 
    variant="body2" 
    gutterBottom 
    noWrap={true} 
    sx={{width:'100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
>
{(cardData.vehicleYear || cardData.vehicleMake || cardData.vehicleModel || cardData.vehicleTrimPackage) 
        ? cardData.vehicleYear + " " + cardData.vehicleMake + " " + cardData.vehicleModel + " " + (cardData.vehicleTrimPackage || "")
        : "No vehicle data found"}
            </Typography>
            <Typography variant="body2" color="textSecondary">
            {cardData.vin || "No VIN found"}
            </Typography>
          </Box>
          <Box>
          <Box display="flex" alignItems="center">
    <Typography variant="body2" gutterBottom>
        Start: {" "}
    </Typography>
    
  
    <DatePicker

      value={dateValue}
      onChange={(newDate) => {
        
        updateStartInDate(newDate); // Pass the new date directly
    }}
      format="MM/DD/YYYY"
      open={isDatePickerOpen}
      onOpen={() => setIsDatePickerOpen(true)}
      onClose={() => setIsDatePickerOpen(false)}
      TextFieldComponent={() => (
        <Typography variant="body2" gutterBottom onClick={() => setIsDatePickerOpen(true)}>
          {dateValue.format('MM/DD/YYYY')}
        </Typography>
      )}
    />





</Box>
            {/* <Typography variant="body2" gutterBottom>
              In: {cardData.vehicleInDate}
            </Typography> */}
                        <Typography variant="body2" gutterBottom>
              In: {cardData.vehicleInDate}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Out: {cardData.vehicleOutDate}
            </Typography>
            <Typography variant="body2" color="textSecondary"  
    gutterBottom 
    noWrap={true} 
    sx={{width:'100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
              Total Hours: {parseFloat(cardData.totalHours).toFixed(2) || 0}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Price: ${(cardData.priceInCents / 100).toFixed(2)}
            </Typography>
            






          </Box>

        </Box>
      </CardContent>
      <div>
            {showModal && (
                <Modal open={showModal}>
                <Box
                    sx={{
                        width: '25rem',
                        bgcolor: 'background.default',
                        p: 4, // Increased padding for better spacing
                        borderRadius: '8px', // Rounded corners
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                  <form onSubmit={handleFormSubmit}>
                    <h1 style={{ marginBottom: '1rem', fontWeight: 'bold' }}>Send SOS</h1>
                    <h2 style={{ marginBottom: '1.5rem', fontWeight: 'normal' }}>Select users to notify</h2>
                    {modalUsers.map(user => (
                        <div key={user.id} style={{ marginBottom: '0.5rem', display: 'flex', alignItems: 'center' }}>
                            <input 
                                type="checkbox" 
                                name="selectedUser" 
                                value={user.id} 
                                style={{ marginRight: '0.5rem' }} 
                            />
                            {user.user.first_name} {user.user.last_name}
                        </div>
                    ))}
                    <textarea 
                        style={{ 
                            width: '100%', 
                            padding: '0.5rem', 
                            borderRadius: '4px', 
                            border: '1px solid #ccc', 
                            marginBottom: '1rem' 
                        }} 
                        name="message"
                        placeholder="Type your message here..."
                    />
                    <button 
                        type={"submit"}
                        style={{ 
                            padding: '0.5rem 1rem', 
                            borderRadius: '4px', 
                            backgroundColor: '#007BFF', 
                            color: 'white', 
                            border: 'none', 
                            cursor: 'pointer' 
                        }}
                        onMouseOver={(e) => e.target.style.backgroundColor = '#0056b3'}
                        onMouseOut={(e) => e.target.style.backgroundColor = '#007BFF'}
                    >
                        Send Message
                    </button>
                    <button 
            onClick={()=>setShowModal(false)} 
            style={{ 
                position: 'absolute', 
                top: '10px', 
                right: '10px', 
                background: 'transparent', 
                border: 'none', 
                fontSize: '1.5rem', 
                cursor: 'pointer' 
            }}
        >
            &times;
        </button>
                    </form>
                </Box>
            </Modal>
            
            )}
        </div>
    </Card>

    
  );
});

export default RepairOrderCard;
