import React, { useState, useEffect } from 'react';
import apiClient from "../apiClient";
import { List, Datagrid, TextField } from 'react-admin';
import { useDataProvider, useRefresh } from 'react-admin';

function UserTimecardList(props) {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    useEffect(() => {
        const handleShopChange = async () => {

            refresh();
        };

        window.addEventListener('shopChanged', handleShopChange);

        return () => {
            window.removeEventListener('shopChanged', handleShopChange);
        };
    }, [dataProvider, refresh]);
    return (
        <List {...props}>
        <Datagrid rowClick="show">
            <TextField source="username" label="Username" />
            <TextField source="email" label="Email" />
            <TextField source="first_name" label="First Name" />
            <TextField source="last_name" label="Last Name" />
            <TextField source="is_active" label="Active" />
            <TextField source="display_string" label="Account Level" />
        </Datagrid>
    </List>
);
}

export default UserTimecardList;
