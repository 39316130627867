import {React,useState,useEffect, useMemo} from 'react';
import MaterialTable from 'material-table';
import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Box, Tooltip, Typography } from '@material-ui/core';

import { CircularProgress } from '@material-ui/core';

const tableIcons = {
Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};



  const DataTable = ({ data, onDataSelect, filter, stageFilter }) => {
    const [loading, setLoading] = useState(true);
  
  
    useEffect(() => {
    if (data && data.length > 0) {
      setLoading(false);
    }
  }, [data]);

  const filteredData = useMemo(() => {
    let result = data;

    if (filter) {
      result = result.filter(item => item[filter] !== null && item[filter] !== undefined);
    }

    if (stageFilter) {
      result = result.filter(item => item.stage_detail.name === stageFilter);
    }

    return result;
  }, [data, filter, stageFilter]);

  
  const flagData = {
    allParts: { color: 'lightgreen', name: 'All Parts Here' },
    readyPaint: { color: 'orange', name: 'Ready for Paint' },
    allRepairNoParts: { color: 'blue', name: 'All Repairs - no parts' },
    hospital: { color: 'red', name: 'HOSPITAL' },
    requireFrame: { color: 'black', name: 'Requires Frame' },
    requireMech: { color: 'pink', name: 'Requires Mechanical' },
    requireGlass: { color: 'grey', name: 'Requires Glass' },
    needsCalibration: { color: 'purple', name: 'Needs Calibration' },
  };
  
  const darkColors = ['black', 'blue', 'red', 'purple'];

  const getTextColor = (backgroundColor) => {
    return darkColors.includes(backgroundColor) ? '#fff' : '#000';
  };
  

  
  const stageColorMapping = {
    "Repair Planning": "#FAE9B0", // Replace with actual stage names and colors
    "Waiting to Start": "#F1AE6A",
    "Frame": "#CB4C4F",
    "Mechanical": "#B66C80",
    "Body": "#895B96",
    "Refinish": "#709FC1",
    "Assembly": "#90B270",
    "Detail": "#5F8356",
    "Ready for Delivery": "#202424",
    // Add more stages as needed...
  };
  const getRowStyle = (rowData) => {
    const stageName = rowData.stage_detail && rowData.stage_detail.name;
    const backgroundColor = stageColorMapping[stageName] || "#FFF"; // Default to white if stage not found
    const color = ["#FAE9B0", "#F1AE6A"].includes(backgroundColor) ? "#000000" : "#ffffff"; // Adjust text color based on background
    return { backgroundColor, color };
  };

  const cellStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '200px', // adjust as needed
    height: '50px', // adjust as needed
  };

  return (
    <>
    {loading ? (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    ) : (
    <MaterialTable
    icons={tableIcons}
      title="Repair Orders"
      columns={[
        { title: 'RO#', field: 'ro', sortable: true },
        { title: 'Vehicle In Date', field: 'vehicleInDate', sortable: true, type: 'date',cellStyle: cellStyle },
        { title: 'Vehicle Out Date', field: 'vehicleOutDate', sortable: true, type: 'date',cellStyle: cellStyle },
        { title: 'Start Date', field: 'startInDate', sortable: true, type: 'date',cellStyle: cellStyle },
        { title: 'Total Hours', field: 'totalHours', sortable: true, type: 'numeric' },
        { title: 'Price', field: 'priceInCents', sortable: true,type: 'numeric', render: rowData => rowData.priceInCents ? `$${(rowData.priceInCents / 100).toFixed(2)}` : '-'},
        { title: 'Sales Tax Total', field: 'salesTaxTotalInCents', sortable: true, type: 'numeric',render: rowData => rowData.priceInCents ? `$${(rowData.salesTaxTotalInCents / 100).toFixed(2)}` : '-'},
        { title: 'Status', field: 'status', sortable: true },
        { title: 'Stage', field: 'stage_detail.name', sortable: true,cellStyle: cellStyle },
        { 
          title: 'Flags', 
          field: 'flags', 
          sortable: true, 
          cellStyle: cellStyle, 
          render: rowData => (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {Object.entries(flagData).map(([flag, data]) => (
                rowData[flag] ? (

                      <Typography style={{ color: 'black', fontSize: '0.75rem' }}>
                        {data.name}
                      </Typography>

                ) : null
              ))}
            </div>
          )
        },
        // { title: 'Shop', field: 'shop.name', sortable: true },
        { title: 'Created', field: 'created', sortable: true, type: 'datetime',cellStyle: cellStyle },
        { title: 'Modified', field: 'modified', sortable: true, type: 'datetime' },
        { title: 'Is Closed', field: 'isClosed', sortable: true, type: 'boolean' },
        { title: 'Insurance Company', field: 'insuranceCompany', sortable: true,cellStyle: cellStyle },
        { title: 'VIN', field: 'vin', sortable: true },
        { title: 'Vehicle Year', field: 'vehicleYear', sortable: true, type: 'numeric' },
        { title: 'Vehicle Make', field: 'vehicleMake', sortable: true },
        { title: 'Vehicle Model', field: 'vehicleModel', sortable: true, cellStyle: cellStyle },
        { title: 'Vehicle Body Style', field: 'vehicleBodyStyle', sortable: true,cellStyle: cellStyle },
        { title: 'Vehicle Trim Package', field: 'vehicleTrimPackage', sortable: true },
        { title: 'Vehicle Color', field: 'vehicleColor', sortable: true,cellStyle: cellStyle },
        { title: 'Vehicle Interior Color', field: 'vehicleInteriorColor', sortable: true },
        { title: 'Vehicle Paint Code', field: 'vehiclePaintCode', sortable: true },
        { title: 'Vehicle Trim Code', field: 'vehicleTrimCode', sortable: true },
        { title: 'Vehicle Production Date', field: 'vehicleProductionDate', sortable: true, type: 'date' },
        { title: 'Vehicle Mileage In', field: 'vehicleMileageIn', sortable: true, type: 'numeric' },
        { title: 'Customer Name', field: 'customerName', sortable: true,cellStyle: cellStyle },
        { title: 'Estimator Name', field: 'estimatorName', sortable: true,cellStyle: cellStyle },
        { title: 'Estimator ID', field: 'estimatorId', sortable: true },
        { title: 'All Parts', field: 'allParts', sortable: true, type: 'boolean' },
        { title: 'Ready Paint', field: 'readyPaint', sortable: true, type: 'boolean' },
        { title: 'All Repair No Parts', field: 'allRepairNoParts', sortable: true, type: 'boolean' },
        { title: 'Hospital', field: 'hospital', sortable: true, type: 'boolean' },
        { title: 'Require Frame', field: 'requireFrame', sortable: true, type: 'boolean' },
        { title: 'Require Mech', field: 'requireMech', sortable: true, type: 'boolean' },
        { title: 'Require Glass', field: 'requireGlass', sortable: true, type: 'boolean' },
        {title: 'Needs Calibration', field: 'needsCalibration', sortable: true, type: 'boolean' },
      ]}
      
      data={filteredData}  // Pass the filtered data here
      options={{
        sorting: true,
        pageSize: 20,
        pageSizeOptions: [10, 20, 50],
        rowStyle: rowData => getRowStyle(rowData),
      }}
      onRowClick={(event, rowData) => onDataSelect(rowData.id)}
    />
)}</>
  );
};

export default DataTable;
