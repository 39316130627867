import React, { useState, useEffect } from 'react';
import { SimpleShowLayout, TextField, DateField, ArrayField, Datagrid,Show } from 'react-admin';
import { Select, MenuItem } from '@mui/material';
import apiClient from "../apiClient";
import { useRecordContext, useGetRecordId } from 'react-admin';
export const UserTimecardShow = (props) => {
    const [filter, setFilter] = useState('all');
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const recordId = useGetRecordId();

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiClient.get(`timecards/${recordId}`);
                setData(response.data);
                // console.log("Timecard data", response.data)
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchData();
    }, [props.id]);

    const filterTimecards = (timecards) => {
        const now = new Date();
        switch (filter) {
            case 'week':
                const startOfWeek = new Date(now);
                startOfWeek.setDate(now.getDate() - now.getDay());
                startOfWeek.setHours(0, 0, 0, 0);
                return timecards.filter(tc => new Date(tc.clock_in) >= startOfWeek);
            case 'month':
                const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
                return timecards.filter(tc => new Date(tc.clock_in) >= startOfMonth);
            case 'year':
                const startOfYear = new Date(now.getFullYear(), 0, 1);
                return timecards.filter(tc => new Date(tc.clock_in) >= startOfYear);
            default:
                return timecards;
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const filteredTimecards = filterTimecards(data.timecards || []);
    // console.log("Filtered timecards", filteredTimecards)
    const SecondsToHoursField = ({ source }) => {
        const record = useRecordContext();
        // console.log(source)
        const secondsValue = record[source];
        // console.log(record)
        // console.log("Seconds value", secondsValue)
        const seconds = typeof secondsValue === 'string' ? parseFloat(secondsValue) : secondsValue;
    
        if (!seconds || isNaN(seconds)) {
            // console.log("Seconds", seconds);
            return( <span>0</span>)}; // or return null if you prefer
    
        const hours = (seconds / 3600).toFixed(2); // Convert seconds to hours and round to 2 decimal places
        // console.log("Hours", hours)
        return <span>{hours}</span>;
    };

    const totalHours = filteredTimecards.reduce((acc, tc) => {
        const seconds = typeof tc.total_seconds === 'string' ? parseFloat(tc.total_seconds) : tc.total_seconds;
        return acc + (seconds / 3600);
    }, 0).toFixed(2);
    
    return (
        <Show>
        <SimpleShowLayout key={JSON.stringify(data)}>
            <TextField source="username" label="Username" record={data} />
            <div>Total Hours for Selected Period: {totalHours}</div>
            <Select value={filter} onChange={handleFilterChange}>
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="week">This Week</MenuItem>
                <MenuItem value="month">This Month</MenuItem>
                <MenuItem value="year">This Year</MenuItem>
            </Select>
         
            <ArrayField source="timecards" record={{ timecards: filteredTimecards }}>
                <Datagrid>
                    <DateField source="clock_in" label="Clock In" showTime />
                    <DateField source="clock_out" label="Clock Out" showTime />
                    <SecondsToHoursField source="total_seconds" label="Total Hours"  />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
        </Show>
    );
};
