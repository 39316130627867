import { red } from "@mui/material/colors";
import apiClient from "./apiClient";

const authProvider = {
  login: ({ username, password }) => {
    // console.log("Logging in");
    return apiClient
      .post("token/", { username, password })
      .then((response) => {
        // Store login state in local storage
        localStorage.setItem("isLoggedIn", "true");
        // console.log("logged in done")
        return Promise.resolve({redirectTo:'/kanban'});
      })
      .catch((error) => {
        console.error(error);
        throw new Error("Network error");
      });
  },
  logout: () => {
    // console.log("Logging out");
 
    // Call your logout API
      return apiClient
      .post("logout/")
      .then((response) => {
        // console.log(response)
        // Reset login state in local storage
        // console.log(localStorage.getItem("isLoggedIn"))
        localStorage.setItem("isLoggedIn", "false");
        // console.log(localStorage.getItem("isLoggedIn"))
        return Promise.resolve();
      })
      .catch((error) => Promise.reject(error));
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      // console.log("logging out because 401/403")
      // Logout user
      localStorage.setItem("isLoggedIn", "false");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
        // Add a condition to check the server when the local state indicates that the user is not logged in
        // console.log("checkauth")
        return apiClient
        .post("token/refresh/")
        .then((response) => {
          // If the server responds with a status in the 200 range, the token is valid
          if(response.status === 200){
          // console.log("logged in")
          localStorage.setItem("isLoggedIn", "true");
          return Promise.resolve();
          }else{
            // console.log("not logged in")
            localStorage.setItem("isLoggedIn", "false");
            return Promise.reject({redirectTo:"/login"});
          }
        })
        .catch(() => {
          // If the server responds with an error, reject the promise
          localStorage.setItem("isLoggedIn", "false");
          return Promise.reject();
        });
    // console.log("checkauth proceeding")
  },
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
