import { Check } from '@mui/icons-material';
import { List, Datagrid, Edit, SimpleForm, TextInput, BooleanInput, FormDataConsumer, SelectInput, required} from 'react-admin';
import {
ReferenceArrayInput,
    SelectArrayInput, CheckboxGroupInput
  } from 'react-admin';
  import { Grid } from '@mui/material';

  import { useQuery } from 'react-query';
  import apiClient from "../apiClient"; // Make sure to update the path
  import { useGetOne } from 'react-admin';
  import { useParams } from 'react-router-dom';
  import { useDataProvider } from "react-admin";
import { red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import React, { PureComponent, useState, useEffect} from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, LabelList, Legend, ResponsiveContainer } from 'recharts';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import MuiTooltip from '@mui/material/Tooltip';
const Reports = () => {
  const [data, setData] = useState({});
  const [hasData, setHasData] = useState(false);
  const [stageData, setStageData] = useState([]);
  const [shopId, setShopId] = useState(localStorage.getItem('selectedShopId'));
  const [selectedPeriod, setSelectedPeriod] = useState('year');
  const [message, setMessage] = useState(null); // Store the message from API
  const [scaleType, setScaleType] = useState('linear'); // Default to 'linear'
  const [allStages, setAllStages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedReport, setSelectedReport] = useState('yamazumi');
const fetchData = (currentShopId) => {
    setMessage(null)
    const apiUrl = `/cycle_time/?shop_id=${currentShopId}&period=${selectedPeriod}`; 
    setLoading(true);
    apiClient.get(apiUrl)
        .then(response => {
            const data = response.data;
            console.log("Data response:",data)
            if (data) {
                if (data.individual_times.length === 0 && data.message) {
                    setMessage(data.message);
                    setHasData(false);
                } else {
                    setAllStages(data.stages);
                    const processedData = data.stages.map(stage => {
                        const existingData = data.stage_times.find(item => item.stage_name === stage.name);
                        if (existingData) {
                            return {
                                ...existingData,
                                total_time: parseInt(existingData.total_time, 10)
                            };
                        } else {
                            return {
                                stage_name: stage.name,
                                total_time: 0
                            };
                        }
                    });

                    // console.log(processedData)
                    setStageData(processedData);

                    setData(data);
                    
                    // console.log(data)
                    if (data.message) {
                        setMessage(data.message);
                    } else {
                        setMessage(null);
                    }
                    setHasData(true);
                }
                setLoading(false);
            } else {
                console.error("Unexpected data structure:", data);
                throw new Error("Received unexpected data structure from the server.");
            }
        })
        .catch(error => {
            console.error("Error fetching data:", error);
            setLoading(false);
            setHasData(false);
        });
};

  useEffect(() => {
    fetchData(shopId);

    const handleShopChange = () => {
      const newShopId = localStorage.getItem('selectedShopId');
      setShopId(newShopId);
      fetchData(newShopId);
    };

    // Add event listener to listen for shopChanged event
    window.addEventListener('shopChanged', handleShopChange);

    // Cleanup: remove event listener on component unmount
    return () => {
      window.removeEventListener('shopChanged', handleShopChange);
    };
  }, [shopId, selectedPeriod]); // Re-fetch data when shopId or selectedPeriod changes


  
  useEffect(() => {
      fetchData(shopId);
  }, [shopId, selectedPeriod]);

  const handlePeriodChange = (e) => {
      setSelectedPeriod(e.target.value);
  };
  let cycleTimeText = (typeof data.average_cycle_time_seconds !== 'undefined' && data.average_cycle_time_seconds !== null)
  ? `${(data.average_cycle_time_seconds / 3600).toFixed(2)} hours` 
  : 'No repair orders found';

let avgDeliveriesText = (typeof data.average_deliveries_per_day !== 'undefined' && data.average_deliveries_per_day !== null)
  ? `${data.average_deliveries_per_day.toFixed(2)} vehicles/day` 
  : 'No deliveries data found';


  return (
    <Grid container spacing={3} direction="column" alignItems="center">
        <Grid item xs={12} md={4}>
            <div style={{ padding: '20px', border: '1px solid #ddd', borderRadius: '10px', textAlign: 'center' }}>
                <h2>Reports</h2>
                <FormControl variant="outlined" style={{ minWidth: 200 }}>
  <InputLabel id="report-type-label">Report Type</InputLabel>
  <Select
    labelId="report-type-label"
    value={selectedReport}
    onChange={(e) => setSelectedReport(e.target.value)}
    label="Report Type"
  >
            <MenuItem value="yamazumi">Yamazumi</MenuItem>
            <MenuItem value="avgVehiclesDelivered">Average Vehicles Delivered</MenuItem>
            <MenuItem value="avgCycleTime">Average Cycle Time</MenuItem>
            <MenuItem value="shopEfficiency">Shop Efficiency</MenuItem>
            {/* <MenuItem value="repairReplace">Repair vs Replace</MenuItem> */}
  </Select>
</FormControl>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" style={{ minWidth: 200 }}>
                            <InputLabel id="period-label">Period</InputLabel>
                            <Select
                                labelId="period-label"
                                value={selectedPeriod}
                                onChange={handlePeriodChange}
                                label="Period"
                            >
                                <MenuItem value="year">This Year</MenuItem>
                                <MenuItem value="month">This Month</MenuItem>
                                <MenuItem value="week">This Week</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {selectedReport === 'yamazumi' && (
                                <>
                                <FormControl variant="outlined" style={{ minWidth: 200 }}>
                                    <InputLabel id="scale-type-label">Scale Type</InputLabel>
                                    <Select
                                        labelId="scale-type-label"
                                        value={scaleType}
                                        onChange={(e) => setScaleType(e.target.value)}
                                        label="Scale Type"
                                    >
                                        <MenuItem value="linear">Proportional Scale</MenuItem>
                                        <MenuItem value="log">Logarithmic Scale</MenuItem>
                                    </Select>
                                </FormControl>
                                                            <MuiTooltip title="Proportional Scale is the normal display. Logarithmic is useful when there is a very large difference between two columns.">
                                                            <IconButton aria-label="info">
                                                                <HelpOutlineIcon color="primary"/>
                                                            </IconButton>
                                                        </MuiTooltip>
                                                        </>
                            )}

                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {message && <p style={{color: 'red'}}>{message}</p>}
                    </Grid>

                </Grid>
            </div>
        </Grid>
        <Grid item xs={12} md={8} style={{ minWidth: "75%" }}>
    {loading ? (
        <p>Loading...</p>
    ) : !hasData ? (
        <p>{data.message}</p>
    ) : selectedReport === 'yamazumi' ? (
        <div style={{ width: '100%', height: '30vh' }}>
            <ResponsiveContainer>
                <BarChart data={stageData} layout="horizontal">
                    <YAxis type="number" scale={scaleType} domain={scaleType === 'log' ? [1, 'dataMax'] : [0, 'dataMax + 10%']} allowDataOverflow={true} />
                    <XAxis dataKey="stage_name" type="category" width={80} tick={{ fontSize: 12 }} />
                    <Tooltip />
                    <Bar dataKey="total_time" fill="#8884d8" name="Total Time" label={<LabelList position="insideRight" />} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    ) : selectedReport === 'avgVehiclesDelivered' ? (
        <div>
            <h3>Average Vehicles Delivered</h3>
            <p style={{ fontSize: '20px' }}>{avgDeliveriesText}</p>
        </div>
    ) : selectedReport === 'avgCycleTime' ? (
        <div>
            <h3>Average Cycle Time</h3>
            <p style={{ fontSize: '20px' }}>{cycleTimeText}</p>
        </div>
    ) : 
    selectedReport === 'shopEfficiency' ? (
        <div>
            <h3>Shop Efficiency</h3>
            <p style={{ fontSize: '20px' }}>{cycleTimeText}</p>
        </div>
    )  : null
    }
    </Grid>
  </Grid>
);


};

export default Reports;

