import PublicLayout from '../PublicLayout';
import { Card, CardContent, Typography } from '@mui/material';
import Blog from '../blog_comp/Blog.js';
const Home = () => {

    return <Blog sx={{width:'100%'}}/>

    return(
        <Card style={{ margin: '2em', padding: '2em' }}>
        <CardContent>
            <Typography variant="h4" gutterBottom>Welcome to Our Product</Typography>
            <Typography variant="body1">Here's some information about our product...</Typography>
            {/* Add more content as desired */}
        </CardContent>
    </Card>

    )



    return (
        <div>

            <PublicLayout>
            <h1>Home</h1>
            <p>Welcome to the home page</p>

            </PublicLayout>
        </div>
    )
}

export default Home