import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import apiClient from "../apiClient";

function TimeClock() {
    const [clockedIn, setClockedIn] = useState(false); // Assuming initially not clocked in
    const [username, setUsername] = useState(''); // Assuming initially not clocked in
    const fetchTimecardStatus = async () => {
        try {
            const response = await apiClient.get(`timeclock/`); // Adjust the endpoint as needed
            if (response.status === 200) {
                // console.log('Timecard status:', response.data)
                setUsername(response.data.username);
                setClockedIn(response.data.clockedIn);
            } else {
                console.error('Failed to fetch timecard status:', response.data);
            }
        } catch (error) {
            console.error('Error fetching timecard status:', error);
        }
    };
    
    
    useEffect(() => {
        fetchTimecardStatus();
    }, []);
    

    const handleClockIn = async () => {
        try {
            setClockedIn(true);
            
            
            const response = await apiClient.post(`timeclock/clock_in/`); // Adjust the endpoint as needed
            if (response.status === 200) {
                // console.log('Successfully clocked in:', response.data);
       
            } else {
                setClockedIn(false)
                await fetchTimecardStatus()
                console.error('Failed to clock in:', response.data);
            }
        } catch (error) {
            setClockedIn(false)
            await fetchTimecardStatus()
            console.error('Error clocking in:', error);
        }
    };
    
    const handleClockOut = async () => {
        try {
            setClockedIn(false);
            
            
            const response = await apiClient.post(`timeclock/clock_out/`); // Adjust the endpoint as needed
            if (response.status === 200) {
                // console.log('Successfully clocked out:', response.data);
         
   
            } else {
                setClockedIn(true)
                await fetchTimecardStatus()
                console.error('Failed to clock out:', response.data);
            }
        } catch (error) {
            setClockedIn(true)
            await fetchTimecardStatus()
            console.error('Error clocking out:', error);
        }
    };
    

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>

            {clockedIn ? (
               <Button variant="contained" color="primary" style={{whiteSpace: 'nowrap' }}  onClick={handleClockOut}>
                    Clock Out
                </Button>
            ) : (
<Button variant="contained" color="primary" style={{whiteSpace: 'nowrap' }} onClick={handleClockIn}>
    Clock In
</Button>
            )}
                        <Typography variant="h9" style={{ marginLeft: '16px',whiteSpace: 'nowrap',}}>
                User: {username}
            </Typography>
        </div>
    );
    
}

export default TimeClock;

