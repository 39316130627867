import { Admin, Resource, ListGuesser, Layout, CustomRoutes, } from "react-admin";
import { Route } from "react-router-dom";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import ShopSelector from "./components/ShopSelector";
import AutomobileKanban from "./components/AutomobileKanban";
import MyAppBar from "./components/MyAppBar";
import { UserSettingsList, UserSettingsEdit, UserSettingsCreate } from './components/userSettings';
import CustomLayout from './CustomLayout';
import LogoutButton from "./components/LogoutButton";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Reports from "./components/reports";
import Entry from "./components/Entry";
import LoginPage from "./components/Login";
import RepairOrderShow from "components/RepairOrderShow";
import RepairOrderEdit from "components/RepairOrderEdit";
import '@mui/icons-material';
import '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import InvoiceScan from "components/InvoiceScan";
import UserTimecardList from "components/UserTimecardList";
import { UserTimecardShow } from "components/UserTimecardShow";
import NotificationSettings from "components/NotificationSettings";
import PasswordResetConfirm from "components/PasswordConfirm";
import PasswordResetRequest from "components/PasswordReset";
import ScheduleView from "components/ScheduleView";
import { AppointmentEdit, AppointmentShow } from "components/Appointments";
const App = () => (
  
  <div>
<Admin loginPage={LoginPage} dashboard={Home} dataProvider={dataProvider} authProvider={authProvider} layout={CustomLayout} logoutButton={LogoutButton}>
   <CustomRoutes noLayout >
  <Route path="/" element={<Home/>} />
  <Route path="/contact" element={<Contact/>} />
  <Route path="/password-reset" element={<PasswordResetRequest/>} />
  <Route path="/password-reset-confirm/:uidb64/:token" element={<PasswordResetConfirm/>} />
  {/* <Route path="/entry" element={<Entry/>} /> */}
   </CustomRoutes>
   <CustomRoutes >
  <Route path="/reports" element={<Reports/>} />
  <Route path="/schedule" element={<ScheduleView/>} />
  <Route path="/schedule/:id/show" element={<AppointmentShow />} />
  <Route path="/schedule/:id/edit" element={<AppointmentEdit />} />
   </CustomRoutes>
    <Resource name="kanban" list={AutomobileKanban} />
    <Resource name="stages" list={ListGuesser} />
    <Resource name="repairOrders" list={ListGuesser} />
    <Resource name="profile/" list={UserSettingsList} edit={UserSettingsEdit} create={UserSettingsCreate} />
    <Resource name="shops" list ={ListGuesser} />
    <Resource name="shopselect" list={ShopSelector} />
    <Resource name="repairorder" show={RepairOrderShow} />
    <Resource name="parts" create={InvoiceScan} />
    <Resource name="timecards" list={UserTimecardList} show={UserTimecardShow} />
    <Resource name="notificationSettings" list={NotificationSettings} edit ={NotificationSettings}/>



  </Admin>
  </div>
);

export default App;
