import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, makeStyles } from '@material-ui/core';
import apiClient from '../apiClient'; // Import your apiClient
const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
    width: '100%', // Adjust the width as needed
  },
  submitButton: {
    marginBottom: theme.spacing(2),
  },
}));

const PasswordResetRequest = ({ onBack }) => {
    const [email, setEmail] = useState('');
    const classes = useStyles();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await apiClient.post('/password-reset/', { email });
     
            alert(response.data.message); // Consider using a more integrated notification system
        } catch (error) {
            alert(error.response.data.error); // Consider using a more integrated notification system
        }
    };

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <TextField 
                type="email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                placeholder="Enter your email"
                required
                className={classes.inputField}
          
            />
            <Button type="submit" variant="contained" color="primary" className={classes.submitButton}>
                Send Reset Link
            </Button>

        </form>
    );
};

export default PasswordResetRequest;
