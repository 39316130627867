import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
} from 'react-admin';
import { Grid, styled, Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

const StyledSimpleForm = styled(SimpleForm)({
  padding: '16px',
  backgroundColor: grey[100],
});

const StyledGridContainer = styled(Grid)({
  marginTop: '16px',
});

const StyledGridItem = styled(Grid)({
  padding: '16px',
  backgroundColor: 'white',
  borderRadius: '8px',
  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
});

const FieldWithLabel = ({ label, children }) => (
  <Box mb={2}>
    <Typography variant="h6" gutterBottom>{label}</Typography>
    {children}
  </Box>
);

const RepairOrderEdit = (props) => (
  <Edit {...props}>
    <StyledSimpleForm>
      <StyledGridContainer container spacing={2}>
        <StyledGridItem item xs={12} sm={6} md={4}>
          <FieldWithLabel label="RO"><TextInput source="ro" /></FieldWithLabel>
          <FieldWithLabel label="Vehicle In Date"><DateInput source="vehicleInDate" /></FieldWithLabel>
          {/* ... other fields ... */}
        </StyledGridItem>
        <StyledGridItem item xs={12} sm={6} md={4}>
          <FieldWithLabel label="Status"><TextInput source="status" /></FieldWithLabel>
          <FieldWithLabel label="Stage"><TextInput source="stage.name" /></FieldWithLabel>
          {/* ... other fields ... */}
        </StyledGridItem>
        <StyledGridItem item xs={12} sm={6} md={4}>
          <FieldWithLabel label="Insurance Company"><TextInput source="insuranceCompany" /></FieldWithLabel>
          <FieldWithLabel label="VIN"><TextInput source="vin" /></FieldWithLabel>
          {/* ... other fields ... */}
        </StyledGridItem>
      </StyledGridContainer>
    </StyledSimpleForm>
  </Edit>
);

export default RepairOrderEdit;
