import { memo } from "react";
import { Box, Card } from "@mui/material";

const CardWrapper = memo(({ KanbanCard, cardData, handleClick, uid, setData }) => {
  if (!cardData) {
    console.warn("no card data");
    return null;
  } else if (!cardData.id) {
    // console.log("not rendering");
    return null;
  }

  return (
    <Box
      sx={{ marginBottom: 1 }}
      onClick={handleClick}
    >
      <Card>
        <KanbanCard cardData={cardData} uid={uid} setData={setData}/>
      </Card>
    </Box>
  );
});

export default CardWrapper;
