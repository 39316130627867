import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import logo_header from '../logo_name_horizontal.png';
import { HashLink } from 'react-router-hash-link';
function Header(props) {
  const { sections, title } = props;

  return (
    <Box >
      {/* <Toolbar sx={{ borderBottom: 1, borderColor: 'divider',color:'#3F4E5B'}}>
      
      
      
        <Typography
          component="h2"
          variant="h3"
          color="inherit"
          align="center"
          noWrap
          sx={{ flex: 1, fontWeight: 'bold',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.25)' }}
        >
          <React.Fragment sx={{}}>
          {title}
          </React.Fragment>
          <img src={logo_header} style={{height:80, align:'center'}} />
        </Typography>
      

      </Toolbar> */}
      <Toolbar

        component="nav"
        variant="dense"
        sx={{ justifyContent: 'space-between', overflowX: 'auto', marginTop:1, marginBottom:4,backgroundColor:'#F5F5F5', boxShadow:'0px 1px 0px #9E9E9E', height: 100 }}
      ><div/>
        <img src={logo_header} style={{height:'90%',align:'center'}} />
        
        <div style={{width:'80%', justifyContent:'space-evenly', display:'flex', fontSize:20, alignItems:'center', height:80 }}>
        {sections.map((section) => (
   
          <HashLink
          smooth={true}
            color="black"
            // noWrap
            key={section.title}
            variant="body2"
            to={section.url}
            style={{
              textDecoration: 'none', // Removes underline
              color: 'black', // Set your desired color
              // other styles...
            }}
          >
            {section.title}
          </HashLink>
       
        ))}
        </div>
      </Toolbar>
    </Box>
  );
}

Header.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default Header;
