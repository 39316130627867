import { Layout } from "react-admin";
import * as React from 'react';
import MyAppBar from './components/MyAppBar';
import MySidebar from './components/MySidebar';
import Box from '@mui/material/Box';
import { useState } from "react";
import LogoutButton from "./components/LogoutButton";
import { useAuthenticated, useRedirect, useNotify } from "react-admin";

const CustomLayout = (props) => {
    const redirectTo = useRedirect();
    const notify = useNotify();
  
    try {
        useAuthenticated();
    } catch (error) {
        notify('You are not authenticated!');
        redirectTo('/login');
    }

    const [sidebarState,setSidebar] = useState(false);
    const toggleSidebar = () => { setSidebar(!sidebarState); 
        // console.log("Switching sidebar state") 
    }
    const drawerWidth = 240;  // fully expanded width
    const miniDrawerWidth = 55;  // collapsed width
    


    return (
        <Box sx={{}}>
                <MySidebar {...props} key="sidebar" toggleSidebar={toggleSidebar} sidebarState={sidebarState} drawerWidth={drawerWidth} miniDrawerWidth={miniDrawerWidth}/>
                <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: '#F5F5F4', p: 3, padding:0,margin:0,paddingLeft:0,
                marginLeft: sidebarState ? `${drawerWidth}px` : `${miniDrawerWidth}px`,
                width: sidebarState ? `calc(100% - ${drawerWidth}px)` : `calc(100% - ${miniDrawerWidth}px)`,
              
            }}>
                
                    <MyAppBar {...props} logoutButton={LogoutButton} toggleSidebar={toggleSidebar} sidebarState={sidebarState}
                    />
                          <Box
        component="main"
        sx={{
          flex: '1 1 auto',
          overflowY: 'auto',paddingLeft:3
        }}
      >
                    <main >
                        {props.children}
                    </main>
                    </Box>
                </Box>
        </Box>

    );
};

export default CustomLayout;