import React, { useState, useEffect, Fragment } from 'react';
import apiClient from '../apiClient'; // Import your Axios instance
import {List, ListItem, ListItemText, IconButton, TextField, Modal, Box, Checkbox, Button, Chip, Stack, Typography, Menu, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const NotificationSettings = () => {
  const [events, setEvents] = useState([]);
  const [notificationSettings, setNotificationSettings] = useState([]);
  const [assignableUsers, setAssignableUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentEventId, setCurrentEventId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedShopId, setSelectedShopId] = useState(localStorage.getItem('selectedShopId'));

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '80%',
    overflowY: 'auto',
  };
  useEffect(() => {
    const handleShopChange = () => {
      const newShopId = localStorage.getItem('selectedShopId');
      setSelectedShopId(newShopId);
    };
  
    window.addEventListener('shopChanged', handleShopChange);
  
    return () => {
      window.removeEventListener('shopChanged', handleShopChange);
    };
  }, []);
  useEffect(() => {
    apiClient.get('notification-settings/')
      .then(response => {
        // console.log(response.data);
        setEvents(response.data.events);
        setNotificationSettings(response.data.notification_settings);
        setAssignableUsers(response.data.assignable_users);
      })
      .catch(error => {
        console.error('There was an error fetching the settings', error);
      });
  }, []);
  const handleOpenModal = (eventId) => {
    setSelectedUsers([]); // Reset selected users when opening modal
    setCurrentEventId(eventId); // Set the current event ID
    setModalOpen(true);
};
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleUserCheck = (userId) => {
    setSelectedUsers(prev => {
      if (prev.includes(userId)) {
        return prev.filter(id => id !== userId);
      } else {
        return [...prev, userId];
      }
    });
  };

  const handleSubmitUsers = () => {
    // API call to add selectedUsers to currentEventId
    // Update the state to reflect the new users added
    handleCloseModal();
  };
  // Function to find the users for a given event
  const getUsersForEvent = (eventId) => {
    const parsedSelectedShopId = parseInt(selectedShopId, 10); // Parse to integer if it's a string
    return notificationSettings
      .filter(setting => setting.event === eventId && parseInt(setting.shop, 10) === parsedSelectedShopId)
      .map(setting => ({ id: setting.id, name: setting.full_name }));
  };
  const handleAddUserClick = (event, eventId) => {
    setCurrentEventId(eventId);
    setAnchorEl(event.currentTarget);
  };

  const handleAddUser = (userId) => {
    if (currentEventId && userId) {
        const shopId = localStorage.getItem('selectedShopId');
        const data = {
            user: userId,
            event: currentEventId,
            shop: shopId
        };

        apiClient.post('notification-settings/update_notification_setting/', data)
            .then(response => {
                // console.log("User added successfully:", response.data);
                // Update notificationSettings state
                const updatedSetting = response.data; // Assuming this is the new or updated setting
                setNotificationSettings(prevSettings => {
                    const existing = prevSettings.find(s => s.id === updatedSetting.id);
                    if (existing) {
                        return prevSettings.map(s => s.id === updatedSetting.id ? updatedSetting : s);
                    } else {
                        return [...prevSettings, updatedSetting];
                    }
                });
            })
            .catch(error => {
                console.error("Error adding user:", error);
            })
            .finally(() => {
                setModalOpen(false);
            });
    }
};

  const handleClose = () => {
    setAnchorEl(null);
  };
  const getAssignableUsersForEvent = (eventId) => {
    const assignedUserIds = notificationSettings
        .filter(setting => setting.event === eventId && parseInt(setting.shop, 10) === parseInt(selectedShopId, 10))
        .map(setting => setting.user);

    // Filter out users who are already assigned to this event for the selected shop
    return assignableUsers.filter(user => !assignedUserIds.includes(user.id));
};

  // Function to handle removing a user
  const handleRemoveUser = (settingId) => {
    apiClient.delete(`notification-settings/${settingId}/delete_notification_setting/`)
      .then(response => {
        // console.log("User removed successfully:", response.data);
        // Update the notificationSettings state to remove the deleted setting
        setNotificationSettings(prevSettings => prevSettings.filter(s => s.id !== settingId));
      })
      .catch(error => {
        console.error("Error removing user:", error);
      });
  };
  return (
    <div style={{marginTop:20}}>
      <Typography variant="h5" gutterBottom>
        Notification Settings
      </Typography>
      <Typography variant="body1" gutterBottom>
        In this menu you can set up which users receive notifications for each event.
      </Typography>
      <List>
        {events.map(event => (
          <ListItem key={event.id} divider>
            <Stack spacing={2} width="100%">
              <Typography variant="h6">{event.name}</Typography>
              <Stack direction="row" spacing={1}>
                {getUsersForEvent(event.id).map(user => (
                  <Chip
                    key={user.id}
                    label={user.name}
                    onDelete={() => handleRemoveUser(user.id)}
                    deleteIcon={<DeleteIcon />}
                  />
                ))}
              </Stack>
              <Stack direction="row" spacing={1}>
              <Button
    variant="contained"
    color="primary"
    onClick={() => handleOpenModal(event.id)}
    startIcon={<AddIcon />}
>
    Add User
</Button>
              </Stack>
            </Stack>
          </ListItem>
        ))}
      </List>
      <Modal
  open={modalOpen}
  onClose={handleCloseModal}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      Select User to Add
    </Typography>
    {currentEventId && getAssignableUsersForEvent(currentEventId).map(user => (
      <div 
        key={user.id} 
        style={{ padding: '10px', cursor: 'pointer', transition: 'background-color 0.3s' }}
        onMouseEnter={(e) => e.target.style.backgroundColor = '#f0f0f0'}
        onMouseLeave={(e) => e.target.style.backgroundColor = ''}
        onClick={() => handleAddUser(user.id)}
      >
        {user.full_name || user.first_name + ' ' + user.last_name}
      </div>
    ))}
  </Box>
</Modal>
    </div>
  );
};

export default NotificationSettings;