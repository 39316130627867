// MyAppBar.js
import * as React from 'react';
import {UserMenu, MenuItemLink, useLogout, Notification } from 'react-admin';
import Settings from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Box,Typography, Container, Toolbar, Drawer,AppBar, Menu} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ShopSelector from "./ShopSelector";
import { useDataProvider, useAuthenticated, useRedirect, useNotify } from "react-admin";
import TimeClock from './TimeClock';
import logo_small from '../logo_name_horizontal2.png';

export default function MyAppBar({props,toggleSidebar,sidebarState, logoutButton}){
  const redirectTo = useRedirect();
  const notify = useNotify();

  try {
      useAuthenticated();
  } catch (error) {
      notify('You are not authenticated!');
      redirectTo('/login');
  }


  const ConfigurationMenu = ({ onClick }) => {
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };

    const logout = useLogout();
    const handleClick = () => {
        logout();
    };

    return(
        <Box>
   
   <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
        <Menu
        id="menu-appbar"
        anchorEl={anchorEl}

        open={Boolean(anchorEl)}
        onClose={handleClose}

      >
        <MenuItem onClick={handleClick}>Logout</MenuItem>
      </Menu>
      </Box>
    )



    // <MenuItem>
    // <MenuItemLink
    //     to="/settings"
    //     primaryText="Settings"
    //     leftIcon={<Settings />}
    //     onClick={onClick} // close the menu on click
    // />
    // <MenuItemLink
    //             to="/"
    //             primaryText="Logout"
    //             leftIcon={<ExitToAppIcon />}
    //             onClick={handleClick}
    //         />
    // </MenuItem>
    

};



const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));


  const CustomSearch = () => {
    return (
        <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
    )
    }

    const CustomTitle = () => {
      // <IconButton sx={{
      //   transition: 'transform 1.0s',
      //   transform: sidebarState ? 'rotate(0deg)' : 'rotate(180deg)'
      // }}
      // onClick={toggleSidebar}
      // >
        
      //   <MenuOpenIcon /></IconButton>
        // return (
        //     <Typography variant="h6" color="black">

        //         Revv Production
                
        //     </Typography>


        // );
        return(
          <Box
          sx={{
              height: '50px',
              width:'300px',
              backgroundImage: `url(${logo_small})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center', marginTop:1, marginBottom:1
          }}
      />

        )
     };
    return (
   
           <AppBar {...props} position='static' sx={{ backgroundColor: '#fff' , boxShadow: 1, color: 'black', width: '100%', margin:0,padding:0 }}>
            <Toolbar sx={{paddingLeft:'3 !important'}}>
                       <Toolbar sx={{ width: '100%', justifyContent:'space-between',paddingLeft:'0 !important' }}>
                       <CustomTitle/>
                        <div>
                       <ShopSelector/>
</div>
                       </Toolbar>
                       <Toolbar>
            </Toolbar>
          <Toolbar sx={{display:'flex',justifyContent:'flex-end',marginLeft:'auto'}}>
            {/* <CustomSearch/> */}
                        <ConfigurationMenu/>
                        </Toolbar>
                
                </Toolbar>
            </AppBar>

    )
 

}