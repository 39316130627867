import { useMemo } from "react";
import { Box } from "@mui/material";
import { DragDropContext } from '@hello-pangea/dnd';
import { Column } from "./Column";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// const organizeCards = (data, stages) => {
//   return stages.map((stage) => {
//     const cardsInStage = data.filter((item) => item.stage === stage.id);
//     // Sort cards by their position
//     const sortedCardsInStage = cardsInStage.sort((a, b) => a.position - b.position);
//     return {
//       id: stage.id,
//       cardIds: sortedCardsInStage.map((item) => item.id),
//     };
//   });
// };


const newTheme = (theme) => createTheme({
  ...theme,
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '1.5rem',
          padding: 5,
          borderWidth: 0,
          boxShadow: 'none',
          border: 'none',
          '&:hover $notchedOutline': {
            borderColor: 'transparent', // Remove border on hover
          },
          '&$focused $notchedOutline': {
            borderColor: 'transparent', // Remove border when focused
          },
        },        notchedOutline: {
          borderColor: 'transparent', // Remove default border
        },
      },
    },


    MuiInputBase: { // This targets the input where the date is displayed
      styleOverrides: {
        input: {
          fontSize: '0.8rem',  // Adjust this value as needed
          height: '0.8rem',    // Adjust this value to change the height
          padding: '0.5rem',
          borderWidth: 0,
          boxShadow: 'none',  
          border: 'none',  // Adjust padding if necessary
        }
      }
    }
  }
});

export const Content = ({ KanbanCard, onChangeStage, stages, data, setData,uid }) => {
 
  const organizedCards = useMemo(() => {
    return stages.map((stage) => {
      const cardsInStage = data.filter((item) => item.stage === stage.id);
      const sortedCardsInStage = cardsInStage.sort((a, b) => new Date(a.vehicleOutDate) - new Date(b.vehicleOutDate));
      return {
        id: stage.id,
        cardIds: sortedCardsInStage.map((item) => item.id),
      };
    });
  }, [data, stages]);
 
 
 
  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
        return;
    }



    const sourceStage = organizedCards.find(stage => stage.id === source.droppableId);
    const destinationStage = organizedCards.find(stage => stage.id === destination.droppableId);

    if (!sourceStage || !destinationStage) {
        console.error("Source or destination stage is undefined");
        return;
    }

    const sourceCardIds = Array.from(sourceStage.cardIds);
    const destinationCardIds = destination.droppableId === source.droppableId ? sourceCardIds : Array.from(destinationStage.cardIds);

    const [removed] = sourceCardIds.splice(source.index, 1);
    destinationCardIds.splice(destination.index, 0, removed);

    let affectedCards = [];

    // If the card is moved to a different column
    if (source.droppableId !== destination.droppableId) {
        const movedCard = data.find(card => card.id === removed);
        if (movedCard) {
            movedCard.stage = destination.droppableId;
            affectedCards.push({
                id: movedCard.id,
                stage: movedCard.stage,
                position: destination.index
            });
        }
    }

    // Update positions for cards in the destination column
    const updatedDestinationCards = destinationCardIds.map((cardId, index) => {
      const card = data.find(c => c.id === cardId);
      if (card) {
          return {
              id: card.id,
              position: index
          };
      }
      return null;
    }).filter(card => card);  // Filter out any null values

    affectedCards = [...affectedCards, ...updatedDestinationCards];

    // Create the new data array
    const newData = data.map(card => {
      const updatedCard = affectedCards.find(uc => uc.id === card.id);
      if (updatedCard) {
          return {
              ...card,
              ...updatedCard
          };
      }
      return card;
    });
    console.log("data at content.js", data)
    setData(newData);
    console.log("newData at content.js", newData)

    // Send bulk update to the server
    onChangeStage(affectedCards,removed);
};




    const getCardIDs = (stage) => {
      return organizedCards.find(item => item.id === stage.id).cardIds;
    };

    return (
      <ThemeProvider theme={newTheme}>
      <DragDropContext onDragEnd={onDragEnd} >
        <Box display="flex">
          {stages.map((stage) => (
            <Column
              KanbanCard={KanbanCard}
              stage={stage}
              cardIds={getCardIDs(stage)}
              data={data}
              key={stage.id}
              uid={uid}
              setData={setData}

            />
          ))}
        </Box>
      </DragDropContext>
      </ThemeProvider>
    );
};



