import React, { useEffect, useState } from 'react';
import { useNotify, useRedirect, Login, LoginForm } from 'react-admin';
import authProvider from '../authProvider'; // Replace with your actual authProvider
import PasswordReset from './PasswordReset'; // Import your PasswordReset component
import PasswordConfirm from './PasswordConfirm'; // Import your PasswordConfirm component
import Button from '@material-ui/core/Button';
const LoginPage = (props) => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const [checkingAuth, setCheckingAuth] = useState(true);
  const [showReset, setShowReset] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    authProvider.checkAuth()
      .then(() => {
        redirectTo('/kanban');
      })
      .catch(() => {
        notify('Not authenticated, please login.');
      })
      .finally(() => {
        setCheckingAuth(false);
      });
  }, [redirectTo, notify]);

  if (checkingAuth) {
    return <div>Checking authentication...</div>;
  }

  const handleShowReset = () => setShowReset(true);
  const handleShowConfirm = () => setShowConfirm(true);
  const handleShowLogin = () => {
    setShowReset(false);
    setShowConfirm(false);
  };
const onBack = () => {
    setShowReset(false);
    setShowConfirm(false);
  };
  return (
    <Login {...props}>
      {!showReset && !showConfirm && (
        <>
          <LoginForm />
          <a onClick={handleShowReset} style={{ cursor: 'pointer', marginBottom: '10px', fontSize: 11, color: '#d0d0d0',marginLeft:10 }}>
            Forgot Password?
          </a>
        </>
      )}
      {showReset && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom:15 }}>
        <PasswordReset onConfirm={handleShowConfirm} onBack={handleShowLogin} />
      <Button onClick={onBack} variant="outlined" color="secondary" style={{alignSelf:'center'}}>
                Return to Login
            </Button></div>}
      {showConfirm && 
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom:15 }}>
      <PasswordConfirm onBack={handleShowLogin} />
  <Button onClick={onBack} variant="outlined" color="secondary" style={{alignSelf:'center'}}>
      Return to Login
  </Button></div>
  }
    </Login>
  );
};

export default LoginPage;
