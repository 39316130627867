import {React, useState,useEffect} from 'react';
import { useRedirect } from 'react-admin';
import { Container, Typography, CircularProgress, Box, Grid, TextField, MenuItem  } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import apiClient from "../apiClient";
import { useParams, Link } from 'react-router-dom';


const payerChoices = [
    { id: 'Insurance', name: 'Insurance' },
    { id: 'Self-Pay', name: 'Self-Pay' },

];

const appointmentTypeChoices = [
    { id: 'Drop-Off Repairs', name: 'Drop-Off Repairs' },
    { id: 'Estimate', name: 'Estimate' },
    { id: 'Tow-in', name: 'Tow-in' },
    { id: 'Warranty', name: 'Warranty' },
];



// Detail View Component
export const AppointmentShow = () => {
        const [appointment, setAppointment] = useState(null);
        const { id } = useParams(); // Get the ID from the URL
        const [open, setOpen] = useState(false);

const redirect = useRedirect();

const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this appointment? This action cannot be undone.')) {
        try {
            await apiClient.delete(`appointments/${id}`);
            window.location.href = '/#/schedule'; // Redirect after successful deletion
        } catch (error) {
            console.error('Error deleting appointment:', error);
            alert('Failed to delete appointment. Please try again.');
        }
    }
};

       




    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await apiClient.get(`appointments/${id}`);
                console.log(data['data']);
                setAppointment(data['data']);
            } catch (error) {
                console.error('Error fetching appointment data:', error);
            }
        };

        fetchData();
    }, [id]);

    if (!appointment) return <CircularProgress />;

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Appointment Details
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1"><strong>Appointment Date:</strong> {appointment.appointmentDate}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1"><strong>Shop:</strong> {appointment.shop.name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1"><strong>Customer Name:</strong> {appointment.customerName}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1"><strong>Vehicle Year:</strong> {appointment.vehicleYear}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1"><strong>Vehicle Make:</strong> {appointment.vehicleMake}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1"><strong>Vehicle Model:</strong> {appointment.vehicleModel}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1"><strong>Insurance Company:</strong> {appointment.insuranceCompany}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1"><strong>Claim Number:</strong> {appointment.claimNumber}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1"><strong>Payer:</strong> {appointment.payer}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1"><strong>Appointment Type:</strong> {appointment.appointmentType}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1"><strong>Phone Number:</strong> {appointment.phoneNumber}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1"><strong>Customer Email:</strong> {appointment.customerEmail}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
    <Typography variant="body1"><strong>RO:</strong> {appointment.ro}</Typography>
</Grid>

                </Grid>
<Grid item xs={12}>
    <Link to={`/schedule/${id}/edit`}>
        <IconButton color="primary">
            <EditIcon /><Typography>Edit Appointment</Typography>
        </IconButton>
    </Link>
    <IconButton color="secondary" onClick={handleDelete}>
        <DeleteIcon /><Typography>Delete Appointment</Typography>
    </IconButton>
</Grid>

            </Box>
        </Container>
    );
};
// record={appointment}
// Edit View Component
export const AppointmentEdit = () => {
    const [appointment, setAppointment] = useState(null);
    const { id } = useParams(); // Get the ID from the URL
    const [shops, setShops] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await apiClient.get(`appointments/${id}`);
                setAppointment(data['data']);
            } catch (error) {
                console.error('Error fetching appointment data:', error);
            }
        };

        fetchData();
    }, [id]);
    useEffect(() => {
        async function fetchShops() {
            try {
                const response = await apiClient.get('shops/');
                setShops(response['data']);
            } catch (error) {
                console.error('Error fetching shops data:', error);
            }
        }
        fetchShops();
    }, []);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'shop') {
            const selectedShop = shops.find(shop => shop.id === parseInt(value));
            setAppointment({ ...appointment, shop: selectedShop });
        } else {
            setAppointment({ ...appointment, [name]: value });
        }
    };
    
    const handleSave = async () => {
        try {
            const updatedAppointment = { ...appointment, shop_id: appointment.shop.id };
            await apiClient.put(`appointments/${id}/`, updatedAppointment);
            alert('Appointment updated successfully!');
            window.location.href = `/#/schedule/${id}/show`;
        } catch (error) {
            console.error('Error updating appointment:', error);
            alert('Error updating appointment.');
        }
    };
    

    if (!appointment) return <CircularProgress />;

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Edit Appointment
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Appointment Date"
                            type="datetime-local"
                            name="appointmentDate"
                            value={appointment.appointmentDate}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
    <TextField
        label="Shop"
        name="shop"
        value={appointment.shop.id}
        onChange={handleInputChange}
        select
        fullWidth
    >
        {shops.map((shop) => (
            <MenuItem key={shop.id} value={shop.id}>
                {shop.name}
            </MenuItem>
        ))}
    </TextField>
</Grid>
<Grid item xs={12} sm={6}>
    <TextField
        label="RO"
        name="ro"
        value={appointment.ro}
        onChange={handleInputChange}
        fullWidth
    />
</Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Customer Name"
                            name="customerName"
                            value={appointment.customerName}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Vehicle Year"
                            name="vehicleYear"
                            value={appointment.vehicleYear}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Vehicle Make"
                            name="vehicleMake"
                            value={appointment.vehicleMake}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Vehicle Model"
                            name="vehicleModel"
                            value={appointment.vehicleModel}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Insurance Company"
                            name="insuranceCompany"
                            value={appointment.insuranceCompany}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Claim Number"
                            name="claimNumber"
                            value={appointment.claimNumber}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Payer"
                            name="payer"
                            value={appointment.payer}
                            onChange={handleInputChange}
                            select
                            fullWidth
                        >
                            {payerChoices.map((choice) => (
                                <MenuItem key={choice.id} value={choice.id}>
                                    {choice.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Appointment Type"
                            name="appointmentType"
                            value={appointment.appointmentType}
                            onChange={handleInputChange}
                            select
                            fullWidth
                        >
                            {appointmentTypeChoices.map((choice) => (
                                <MenuItem key={choice.id} value={choice.id}>
                                    {choice.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Phone Number"
                            name="phoneNumber"
                            value={appointment.phoneNumber}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Customer Email"
                            name="customerEmail"
                            value={appointment.customerEmail}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};