import { memo } from "react";
import { List } from "react-admin";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Content } from "./Content";

const Board = ({ KanbanCard, onChangeStage, stages, data, setData,uid }) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {data.length === 0 ? (
          <p>No repair orders match the current filters</p>
        ) : data.length === 1 && data[0] === 0 ? (
          <p>No repair orders found for this shop</p>
        ) : (
          <Content
            KanbanCard={KanbanCard}
            onChangeStage={onChangeStage}
            stages={stages}
            data={data}
            setData={setData}
            uid={uid}
          />
        )}
      </LocalizationProvider>
    </>
  );
};

export default Board;