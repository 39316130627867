import React, { useState } from 'react';
import { useRedirect } from 'react-admin';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
// YourComponent.js
import './calendstyle.css';
import FlagIcon from '@mui/icons-material/Flag';
import Tooltip from '@mui/material/Tooltip';
const localizer = momentLocalizer(moment);

const CalendarComponent = ({ kanbanData, dateField }) => {
    const redirect = useRedirect();
  // Set the height based on the current window's innerHeight
  const height = window.innerHeight * 0.8; // e.g. 80% of the view height. Adjust as needed.
  const [dateRange, setDateRange] = useState({ start: null, end: null }); // Initialize state variable for calendar height
  const [view, setView] = useState('month')




  const CustomEvent = ({ event }) => {
    return (
      <div>
        {event.allParts && 
          <Tooltip title="All Parts Here">
            <FlagIcon style={{ color: 'lightgreen', fontSize: '1rem' }} />
          </Tooltip>
        }
        {event.readyPaint && 
          <Tooltip title="Ready for Paint">
            <FlagIcon style={{ color: 'orange', fontSize: '1rem' }} />
          </Tooltip>
        }
        {event.title}
      </div>
    );
  };
  // Convert kanbanData to events
  const events = kanbanData.map(item => {
    const date = new Date(item[dateField]); // Use the passed dateField prop here
    return {
      title: 'RO#: ' + item.ro+" " +item.vehicleYear+" "+item.vehicleMake+" "+item.vehicleModel, // Modified title
      allDay: true,
      start: date,
      end: date,
      id: item.id,
      stage: item.stage_detail.name,
      allParts: item.allParts,
      readyPaint: item.readyPaint,
    };
  });
  const stageColorMapping = {
    "Repair Planning": "#FAE9B0", // Replace with actual stage names and colors
    "Waiting to Start": "#F1AE6A",
    "Frame": "#CB4C4F",
    "Mechanical": "#B66C80",
    "Body": "#895B96",
    "Refinish": "#709FC1",
    "Assembly": "#90B270",
    "Detail": "#5F8356",
    "Ready for Delivery": "#202424",
    // Add more stages as needed...
  };
  const handleViewChange = (newView, date) => {
    setView(newView);
  
    let start, end;
    if (newView === 'day') {
      start = end = moment(date).startOf('day').toDate();
    } else if (newView === 'week') {
      start = moment(date).startOf('week').toDate();
      end = moment(date).endOf('week').toDate();
    } else {
      start = end = null;
    }
  
    setDateRange({ start, end });
  };
  
  const calculateHeight = () => {
    if (view === 'month') {
      return '150vh';
    // } else if (view === 'week') {

    // } else {
    //   const eventsInView = events.filter(event => {
    //     return event.start >= dateRange.start && event.end <= dateRange.end;
    //   });
  
    //   return `${50 + eventsInView.length*2}vh`;
    // }
  };
  }


  return (
    <div style={{ height: calculateHeight() }}>
      <Calendar
        popup
        onSelectEvent={event => {redirect(`/repairorder/${event.id}/show/`)}}
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '100%' }}
        defaultView={'month'}
        views={['month', 'week', 'day']}
        components={{
          event: CustomEvent,
        }}
        tooltipAccessor={event => `Stage: ${event.stage} - ${event.title}`}
        eventPropGetter={(event, start, end, isSelected) => {
          let backgroundColor = stageColorMapping[event.stage] || "#ffffff";
          let color = ["#FAE9B0", "#F1AE6A", "#CB4C4F", "#B66C80", "#895B96", "#709FC1", "#90B270", "#5F8356"].includes(backgroundColor) ? "#000000" : "#ffffff";
          return { style: { backgroundColor, color } };
        }}
        min={new Date(0, 0, 0, 8)} // Set min and max to the same time
        max={new Date(0, 0, 0, 8)}
        onView={handleViewChange}
      />
    </div>
  );
};

export default CalendarComponent;
