import React, { useEffect, useState } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  FileInput,
  FileField,
  SelectInput,
  useNotify,
  useRedirect,
} from 'react-admin';
import apiClient from 'apiClient';

const InvoiceScan = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [options, setOptions] = useState([]);
  const [shopId, setShopId] = useState(localStorage.getItem('selectedShopId'));

  useEffect(() => {
    const handleShopChange = () => {
      const newShopId = localStorage.getItem('selectedShopId');
      setShopId(newShopId);
    };

    window.addEventListener('shopChanged', handleShopChange);

    return () => {
      window.removeEventListener('shopChanged', handleShopChange);
    };
  }, []);

  useEffect(() => {
    if (shopId) {
      const fetchOptions = async () => {
        try {
          const response = await apiClient.get('/sos/', {
            params: { shop: shopId },
          });
          if (Array.isArray(response.data.data)) {
            // console.log(response.data.data);
            setOptions(response.data.data);
          } else {
            console.error('API response.data.data is not an array:', response.data.data);
            setOptions([]);
          }
        } catch (error) {
          console.error('Error fetching selection options:', error);
          setOptions([]);
        }
      };
      fetchOptions();
    }
  }, [shopId]);

  const handleFormSubmit = async (values) => {
    const { label, number, invoice, email } = values;

    // Client-side validation for file size and type
    if (invoice.rawFile.size > 100 * 1024 * 1024) {
      notify('File size must be no more than 100MB.', 'warning');
      return;
    }

    if (!email) {
      notify('Please select a recipient.', 'warning');
      return;
  }


    const ext = invoice.rawFile.name.split('.').pop().toLowerCase();
    const validExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'gif'];
    if (!validExtensions.includes(ext)) {
      notify('Unsupported file extension. Please upload a PDF or an image.', 'warning');
      return;
    }

    const formData = new FormData();
    formData.append('label', label);
    formData.append('number', number);
    // console.log(email)
    formData.append('email', email);
    formData.append('invoice', invoice.rawFile);

    try {
      await apiClient.post('/upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      notify('Invoice uploaded successfully. Refreshing page.', 'info');
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      notify('Error uploading invoice: ' + error.message, 'warning');
    }
  };

  return (
    <Create {...props} title="Scan Invoices for Parts">
      <SimpleForm
        redirect="list"
        // save={handleFormSubmit}
        onSubmit={handleFormSubmit}
      >
        <TextInput source="label" label="Invoice Name/Label" />
        <TextInput source="number" label="Invoice Number (optional)" />
        <SelectInput
          source="email"
          label="Recipient"
          choices={options.map((option) => ({
            id: option.user.email, // use email as the id
            name: option.user.first_name + ' ' + option.user.last_name,
          }))}
          
        />
        <FileInput source="invoice" label="Invoice" >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export default InvoiceScan;
